import { Col, Row, Space } from "antd";
import Checkbox from "components/Checkbox";
import SessionDivider from "components/SessionDivider";
import SpaceView from "components/Space";
import React, { useEffect, useState } from "react";
import {
  Cell,
  ContentReason,
  ContentSection1,
  ContentSection7,
  ContentTag,
  LabelSection1,
  LabelSection2,
  SessionContainer,
  StText,
  TableBody,
  TableBodyRow,
  TableBodyRowTitle,
  TableBodyRowValue,
  TableFooter,
  TableFooterTitle,
  TableFooterValue,
  TableHead,
  TableHeadItem,
  TitleSession,
} from "../styled";
import DAService from "../../../services/DAService";
import { useLocation, useParams } from "react-router-dom";
import { customOtherFeesOrder, getNameFromLink, handleSum } from "utils/helper";
import ItemStayStandard from "./ItemStayStandard";
import ItemOthersFees from "./ItemOthersFees";
import Loading from "../../Loading";
import { GST_RATE } from "../../../constants";
import { HighlightText } from "../claim/sessionB.style";
import { REMOVE_SURGEON_ADMIN_RELEASE_DATE } from "constants/epac.constans";

type Props = {
  isHistory?: boolean;
};

export const EstimateCost = ({ isHistory = false }: Props) => {
  const [data, setData] = useState<any>();
  const [tosps, setTosps] = useState<any>([]);
  const { id } = useParams();
  const location = useLocation();
  const search = location.search;
  const version_id =
    new URLSearchParams(search).get("versionId")?.toString() ?? "";
  const [loading, setLoading] = useState(false);
  const [rate, setRate] = useState({
    createdAt: "",
    id: "1",
    gst: GST_RATE,
    updatedAt: "",
  });
  const [isHideSurgeonAdmin, setIsHideSurgeonAdmin] = useState<boolean>(true);
  
  useEffect(() => {
    if (!id) return;
    handleCallAPI(id?.toString());
  }, [id]);

  useEffect(() => {
    if(data?.professional_fees) {
      // todo Need to update to real release date
      const removeSurgeonAdminReleaseDate = new Date(REMOVE_SURGEON_ADMIN_RELEASE_DATE);
      const createdAt = new Date(data.createdAt);
      const isHide = createdAt > removeSurgeonAdminReleaseDate;
      
      setIsHideSurgeonAdmin(isHide);
    }
  }, [data]);

  const getAllRate = async () => {
    try {
      const res = await DAService.rate.getAll();
      setRate(
        res?.data?.data?.items
          ? res?.data?.data?.items[0]
          : {
              createdAt: "",
              id: "1",
              gst: GST_RATE,
              updatedAt: "",
            }
      );
    } catch (error) {
      setRate({
        createdAt: "",
        id: "1",
        gst: GST_RATE,
        updatedAt: "",
      });
      console.log(error);
    }
  };

  const handleCallAPI = async (id: string) => {
    try {
      setLoading(true);
      const res = !isHistory
        ? await DAService.assessor.getDoctorPACFormB(id)
        : await DAService.historyPAC.getHistoryCaseFormB(version_id, id);
      const newData = res?.data?.data;
      setData(res.data.data);
      // get list tosp
      await getListTosp(
        (res?.data?.data?.professional_fees || []).map(
          (item: any) => item.tosp_id
        )
      );
      if (newData?.rate) {
        setRate(newData.rate);
      } else {
        getAllRate();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getListTosp = async (ids: Array<string>) => {
    try {
      const res = await DAService.assessor.filterTosp(ids);
      setTosps(res?.data?.data?.items || []);
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalProfessionalFee = (dataSessionB: any, keyName: string) => {
    let total = 0;
    dataSessionB?.professional_fees?.map((value: any) => {
      total += getTotalForItemProfessionalFee(value, keyName);
    });
    return total;
  };

  const getTotalForItemProfessionalFee = (value: any, keyName: string) => {
    // eslint-disable-next-line no-lone-blocks
    let value_surgeon_fees = 0;
    let value_assistant_surgeon_fees = 0;
    let value_anaesthetist_fees = 0;
    {
      switch (keyName) {
        case "final_fee":
          value_surgeon_fees = value?.surgeon_fees?.gst_included
            ? (value?.surgeon_fees?.[keyName] ??
                value?.surgeon_fees?.suggest_fee ??
                value?.surgeon_fees?.standard_fee ??
                0) *
              (1 + rate?.gst / 100)
            : value?.surgeon_fees?.[keyName] ??
              value?.surgeon_fees?.suggest_fee ??
              value?.surgeon_fees?.standard_fee ??
              0;
          value_assistant_surgeon_fees = value?.assistant_surgeon_fees
            ?.gst_included
            ? (value?.assistant_surgeon_fees?.[keyName] ??
                value?.assistant_surgeon_fees?.suggest_fee ??
                value?.assistant_surgeon_fees?.standard_fee ??
                0) *
              (1 + rate?.gst / 100)
            : value?.assistant_surgeon_fees?.[keyName] ??
              value?.assistant_surgeon_fees?.suggest_fee ??
              value?.assistant_surgeon_fees?.standard_fee ??
              0;
          value_anaesthetist_fees = value?.anaesthetist_fees?.gst_included
            ? (value?.anaesthetist_fees?.[keyName] ??
                value?.anaesthetist_fees?.suggest_fee ??
                value?.anaesthetist_fees?.standard_fee ??
                0) *
              (1 + rate?.gst / 100)
            : value?.anaesthetist_fees?.[keyName] ??
              value?.anaesthetist_fees?.suggest_fee ??
              value?.anaesthetist_fees?.standard_fee ??
              0;
          break;
        case "suggest_fee":
          value_surgeon_fees = value?.surgeon_fees?.gst_included
            ? (value?.surgeon_fees?.[keyName] ??
                value?.surgeon_fees?.standard_fee ??
                0) *
              (1 + rate?.gst / 100)
            : value?.surgeon_fees?.[keyName] ??
              value?.surgeon_fees?.standard_fee;
          value_assistant_surgeon_fees = value?.assistant_surgeon_fees
            ?.gst_included
            ? (value?.assistant_surgeon_fees?.[keyName] ??
                value?.assistant_surgeon_fees?.standard_fee ??
                0) *
              (1 + rate?.gst / 100)
            : value?.assistant_surgeon_fees?.[keyName] ??
              value?.assistant_surgeon_fees?.standard_fee;
          value_anaesthetist_fees = value?.anaesthetist_fees?.gst_included
            ? (value?.anaesthetist_fees?.[keyName] ??
                value?.anaesthetist_fees?.standard_fee ??
                0) *
              (1 + rate?.gst / 100)
            : value?.anaesthetist_fees?.[keyName] ??
              value?.anaesthetist_fees?.standard_fee;
          break;
        default:
          value_surgeon_fees = value?.surgeon_fees?.gst_included
            ? (value?.surgeon_fees?.[keyName] ?? 0) * (1 + rate?.gst / 100)
            : value?.surgeon_fees?.[keyName];
          value_assistant_surgeon_fees = value?.assistant_surgeon_fees
            ?.gst_included
            ? (value?.assistant_surgeon_fees?.[keyName] ?? 0) *
              (1 + rate?.gst / 100)
            : value?.assistant_surgeon_fees?.[keyName];
          value_anaesthetist_fees = value?.anaesthetist_fees?.gst_included
            ? (value?.anaesthetist_fees?.[keyName] ?? 0) * (1 + rate?.gst / 100)
            : value?.anaesthetist_fees?.[keyName];
      }
      const total =
        value_surgeon_fees +
        (value?.assistant_surgeon ? value_assistant_surgeon_fees : 0) +
        (value?.sedation_require ? value_anaesthetist_fees : 0);
      return total;
    }
  };

  const nth = function (d: number) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <SpaceView size={8} />
      {/*01*/}
      <SessionDivider></SessionDivider>
      <SessionContainer>
        <Row>
          <Col span={24}>
            <TitleSession>Professional Fees</TitleSession>
          </Col>
        </Row>
        <SpaceView size={30} />
        {/* begin one item */}
        {data?.professional_fees?.map((item: any, index: number) => {
          const tosp = tosps.find((x: any) => x.id === item.tosp_id);
          return (
            <>
              <Row
                key={item.tosp_id}
                style={{
                  backgroundColor: "#F3F7FC",
                  padding: "16px 24px 24px 24px",
                  borderRadius: "24px",
                }}
              >
                <Col span={24}>
                  <Row>
                    <Space>
                      <Cell size={"48px"}>
                        <StText>
                          {index + 1} <span>{nth(index + 1)}</span>
                        </StText>
                      </Cell>
                      <div style={{ marginLeft: "16px" }}>
                        <LabelSection1 style={{ color: "#00C1D5" }}>
                          {tosp?.table_code || ""} - {tosp?.tosp_code || ""} -{" "}
                          {tosp?.description || ""}
                        </LabelSection1>
                      </div>
                    </Space>
                  </Row>
                  <SpaceView size={35} />
                  <div style={{ marginLeft: "72px" }}>
                    {item?.patient_diagnosis_report_files &&
                          item?.patient_diagnosis_report_files.length > 0 && (
                          <Row>
                            <Space>
                              <LabelSection1>
                                Do you have the relevant diagnostic report(s) available for this patient?
                              </LabelSection1>
                              <LabelSection2>
                                {item?.patient_diagnosis_report ? "Yes" : "No"}
                              </LabelSection2>
                              {item?.patient_diagnosis_report &&
                                item?.patient_diagnosis_report_files &&
                                item?.patient_diagnosis_report_files.length > 0 &&
                                item?.patient_diagnosis_report_files?.map(
                                  (linkFile: string) => {
                                    return (
                                      <ContentTag style={{ backgroundColor: "#fff" }}>
                                        {getNameFromLink(linkFile)}
                                      </ContentTag>
                                    );
                                  }
                                )}
                            </Space>
                          </Row>
                        )
                      }
                    <SpaceView size={35} />
                    <Row>
                      <Space>
                        <LabelSection1>
                          Does the procedure require anaesthesia (including sedation)?
                        </LabelSection1>
                        <LabelSection2>
                          {item?.sedation_require ? "Yes" : "No"}
                        </LabelSection2>
                      </Space>
                    </Row>
                    {
                      !isHideSurgeonAdmin && (
                        <>
                          <SpaceView size={25} />
                          <Row>
                            <Space>
                              <LabelSection1>
                                Are you administering the anaesthesia (including sedation)?
                              </LabelSection1>
                              <LabelSection2>
                                {item?.sedation_performing ? "Yes" : "No"}
                              </LabelSection2>
                            </Space>
                          </Row>
                        </>
                      )
                    }
                    <SpaceView size={25} />
                    <Row>
                      <Space>
                        <LabelSection1>
                          Do you need assistant surgeon?
                        </LabelSection1>
                        <LabelSection2>
                          {item?.assistant_surgeon ? "Yes" : "No"}
                        </LabelSection2>
                      </Space>
                    </Row>
                    <SpaceView size={25} />
                    <Row>
                      <Col span={24}>
                        <div>
                          <TableHead>
                            <TableHeadItem />
                            <TableHeadItem>Standard</TableHeadItem>
                            <TableHeadItem style={{ color: "#FE6B00" }}>
                              Suggested
                            </TableHeadItem>
                            <TableHeadItem style={{ color: "#01A2FF" }}>
                              Final
                            </TableHeadItem>
                            <TableHeadItem>Is GST included?</TableHeadItem>
                          </TableHead>
                          <TableBody>
                            <TableBodyRow>
                              <TableBodyRowTitle>
                                Surgeon fees
                              </TableBodyRowTitle>
                              <TableBodyRowValue>
                                {item?.surgeon_fees.standard_fee ? (
                                  <PriceView>
                                    {item?.surgeon_fees.standard_fee}
                                  </PriceView>
                                ) : null}
                              </TableBodyRowValue>
                              <TableBodyRowValue>
                                <PriceView>
                                  {item?.surgeon_fees?.suggest_fee ??
                                    item?.surgeon_fees?.standard_fee}
                                </PriceView>
                              </TableBodyRowValue>
                              <TableBodyRowValue>
                                <PriceView color="#01A2FF">
                                  {item?.surgeon_fees.final_fee}
                                </PriceView>
                              </TableBodyRowValue>
                              <TableBodyRowValue>
                                <Checkbox
                                  disable
                                  checked={item?.surgeon_fees?.gst_included}
                                />
                              </TableBodyRowValue>
                            </TableBodyRow>
                            {item?.surgeon_fees.disagree_reason && (
                              <TableBodyRow
                                style={{ padding: " 0 24px 24px 24px" }}
                              >
                                <ContentReason>
                                  <ContentSection7 color="#727C8A">
                                    {item?.surgeon_fees.disagree_reason}
                                  </ContentSection7>
                                </ContentReason>
                              </TableBodyRow>
                            )}
                          </TableBody>
                          {item?.assistant_surgeon && (
                            <>
                              <SpaceView size={20} />
                              <TableBody>
                                <TableBodyRow>
                                  <TableBodyRowTitle>
                                    Assistant surgeon fee
                                  </TableBodyRowTitle>
                                  <TableBodyRowValue>
                                    {item?.assistant_surgeon_fees
                                      .standard_fee ? (
                                      <PriceView>
                                        {
                                          item?.assistant_surgeon_fees
                                            .standard_fee
                                        }
                                      </PriceView>
                                    ) : null}
                                  </TableBodyRowValue>
                                  <TableBodyRowValue>
                                    <PriceView>
                                      {item?.assistant_surgeon_fees
                                        ?.suggest_fee ??
                                        item?.assistant_surgeon_fees
                                          .standard_fee}
                                    </PriceView>
                                  </TableBodyRowValue>
                                  <TableBodyRowValue>
                                    <PriceView color="#01A2FF">
                                      {item?.assistant_surgeon_fees.final_fee}
                                    </PriceView>
                                  </TableBodyRowValue>
                                  <TableBodyRowValue>
                                    <Checkbox
                                      disable
                                      checked={
                                        item?.assistant_surgeon_fees
                                          ?.gst_included
                                      }
                                    />
                                  </TableBodyRowValue>
                                </TableBodyRow>
                                {item?.assistant_surgeon_fees
                                  .disagree_reason && (
                                  <TableBodyRow style={{ padding: " 0 24px" }}>
                                    <ContentReason>
                                      <ContentSection7 color="#727C8A">
                                        {
                                          item?.assistant_surgeon_fees
                                            .disagree_reason
                                        }
                                      </ContentSection7>
                                    </ContentReason>
                                  </TableBodyRow>
                                )}
                                <SpaceView size={16} />
                              </TableBody>
                            </>
                          )}
                          <SpaceView size={20} />
                          {item?.sedation_require && (
                            <TableBody>
                              <TableBodyRow>
                                <TableBodyRowTitle>
                                  Anaesthetist fees
                                </TableBodyRowTitle>
                                <TableBodyRowValue>
                                  {item?.anaesthetist_fees.standard_fee ? (
                                    <PriceView>
                                      {item?.anaesthetist_fees.standard_fee}
                                    </PriceView>
                                  ) : null}
                                </TableBodyRowValue>
                                <TableBodyRowValue>
                                  <PriceView>
                                    {item?.anaesthetist_fees?.suggest_fee ??
                                      item?.anaesthetist_fees.standard_fee}
                                  </PriceView>
                                </TableBodyRowValue>
                                <TableBodyRowValue>
                                  <PriceView color="#01A2FF">
                                    {item?.anaesthetist_fees.final_fee}
                                  </PriceView>
                                </TableBodyRowValue>
                                <TableBodyRowValue>
                                  <Checkbox
                                    disable
                                    checked={
                                      item?.anaesthetist_fees?.gst_included
                                    }
                                  />
                                </TableBodyRowValue>
                              </TableBodyRow>
                              {item?.anaesthetist_fees.disagree_reason && (
                                <TableBodyRow style={{ padding: " 0 24px" }}>
                                  <ContentReason>
                                    <ContentSection7 color="#727C8A">
                                      {item?.anaesthetist_fees.disagree_reason}
                                    </ContentSection7>
                                  </ContentReason>
                                </TableBodyRow>
                              )}
                              <SpaceView size={16} />
                            </TableBody>
                          )}
                          <TableFooter>
                            <TableFooterTitle>
                              Total of {index + 1}
                              {nth(index + 1)} TOSP code
                            </TableFooterTitle>
                            <TableFooterValue>
                              <PriceView>
                                {getTotalForItemProfessionalFee(
                                  item,
                                  "standard_fee"
                                )}
                              </PriceView>
                            </TableFooterValue>
                            <TableFooterValue>
                              <PriceView color={"#FE6B00"}>
                                {getTotalForItemProfessionalFee(
                                  item,
                                  "suggest_fee"
                                )}
                              </PriceView>
                            </TableFooterValue>
                            <TableFooterValue>
                              <PriceView color="#01A2FF">
                                {getTotalForItemProfessionalFee(
                                  item,
                                  "final_fee"
                                )}
                              </PriceView>
                            </TableFooterValue>
                            <TableFooterValue></TableFooterValue>
                          </TableFooter>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <SpaceView size={28} />
            </>
          );
        })}
        <SpaceView size={28} />
        <Row>
          <TableFooter color="#55BED2" style={{ height: 92 }}>
            <TableFooterTitle>Total Professional Fees:</TableFooterTitle>
            <TableFooterValue />
            <TableFooterValue />
            <TableFooterValue>
              <PriceView>
                {getTotalProfessionalFee(data, "suggest_fee")}
              </PriceView>
            </TableFooterValue>
          </TableFooter>
        </Row>
        {/* end one item */}
      </SessionContainer>
      {/* 2 */}
      <SpaceView size={56} />
      <SessionDivider value={{ index: 2 }}></SessionDivider>
      <SpaceView size={28} />
      <SessionContainer>
        <Row>
          <Col span={24}>
            <TitleSession>Attendance Fees</TitleSession>
          </Col>
        </Row>
        {/* <SpaceView size={28} />
        <ItemStayStandard
          rate={rate}
          color="#F3F7FC"
          data={data?.attendance_fees?.standard_stay_length}
          title="Standard Length of Stay"
        /> */}
        {/* end one item */}
        <SpaceView size={28} />
        {data?.attendance_fees?.disagree_fee && (
          <ItemStayStandard
            rate={rate}
            color="#FFF0E6"
            data={data?.attendance_fees?.suggested_stay_length}
            title="Your Suggested Length of Stay"
          />
        )}
        <SpaceView size={28} />
        {data?.attendance_fees?.final_stay_length && (
          <ItemStayStandard
            rate={rate}
            color="#F3F7FC"
            data={data?.attendance_fees?.final_stay_length}
            title="Your Final Length of Stay"
          />
        )}
        {/* end two item */}
      </SessionContainer>
      {/* 3 */}
      <SpaceView size={56} />
      <SessionDivider value={{ index: 3 }}></SessionDivider>
      <SessionContainer>
        <Row>
          <Col span={24}>
            <TitleSession>
                Item(s) charged under Letter of Certification (LC)
            </TitleSession>
            <HighlightText style={{ marginBottom: 0 }}>
                Please only include itemized charges that will be charged under Doctor's LC.
                <br />
                We will not be able to review if itemized breakdown is not given.
                <br />
                Do not include items provided by hospital.
            </HighlightText>
          </Col>
        </Row>
        {Object.entries(customOtherFeesOrder(data?.other_fees) || {}).map(
          ([key, value]: any, index: number) => {
            if (value.enable) {
              return (
                <ItemOthersFees
                  data={value.fee_items}
                  title={key}
                  key={index + Math.floor(Math.random() * 1000)}
                />
              );
            }
            return null;
          }
        )}
        <SpaceView size={28} />
        <Row>
            <TableFooter color="#55BED2" style={{ height: 92 }}>
                <TableFooterTitle style={{ width: 'auto' }}>
                    Item(s) charged under Letter of Certification (LC):
                </TableFooterTitle>
                <TableFooterValue>
                    <PriceView>{data?.total_other_fees}</PriceView>
                </TableFooterValue>
            </TableFooter>
        </Row>
        {/* end three item */}
      </SessionContainer>
      {/* 4 */}
      <SpaceView size={56} />
      <SessionDivider value={{ index: 4 }}></SessionDivider>
      <SessionContainer>
        <Row>
          <Col span={24}>
            <TitleSession>Hospital Charges</TitleSession>
          </Col>
        </Row>
        <SpaceView size={30} />
        <Row>
          <TableFooter color="#55BED2" style={{ height: 92 }}>
            <TableFooterTitle>Total Hospital Charges:</TableFooterTitle>
            <TableFooterValue />
            <TableFooterValue />
            <TableFooterValue>
              <PriceView>{data?.total_hospital_charges}</PriceView>
            </TableFooterValue>
          </TableFooter>
        </Row>
      </SessionContainer>
      {/*5 */}
      <SpaceView size={56} />
      <SessionDivider value={{ index: 5 }}></SessionDivider>
      <SessionContainer>
        <Row>
          <Col span={24}>
            <TitleSession>Bill Size=1+2+3+4</TitleSession>
          </Col>
        </Row>
        <SpaceView size={30} />
        <Row>
          <TableFooter color="#55BED2" style={{ height: 92 }}>
            <TableFooterTitle>Total Bill Size:</TableFooterTitle>
            <TableFooterValue />
            <TableFooterValue />
            <TableFooterValue>
              <PriceView>{data?.total_bill_size}</PriceView>
            </TableFooterValue>
          </TableFooter>
        </Row>
      </SessionContainer>

      <SpaceView size={60} />
      <SessionDivider value={{ index: 6 }}></SessionDivider>
      <SessionContainer>
        <Row>
          <Col span={24}>
            <TitleSession>Other Related Documents</TitleSession>
          </Col>
        </Row>
        <SpaceView size={30} />
        <div>
          {(data?.related_documents || []).map((item: any) => {
            return (
              <>
                <ContentTag style={{ display: "inline-block" }}>
                  <ContentSection1
                    onClick={() => {
                      window.open(item, "_blank");
                    }}
                  >
                    {item.split("/").pop()}
                  </ContentSection1>
                </ContentTag>
                <SpaceView size={8} />
              </>
            );
          })}
        </div>
      </SessionContainer>
    </>
  );
};

export const PriceView = (props: any) => {
  return (
    <span style={{ color: props.color, fontSize: "16px" }}>
      {props.children !== null &&
      props.children !== undefined &&
      parseFloat(props.children) >= 0
        ? parseFloat(props.children).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        : "-"}
    </span>
  );
};
