/* eslint-disable no-lone-blocks */
import { MentionData } from "@draft-js-plugins/mention";
import { Col, Input, Row, Space, Select } from "antd";
import Checkbox from "components/Checkbox";
import SessionDivider from "components/SessionDivider";
import SpaceView from "components/Space";
import { forwardRef, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import DAService from "services/DAService";
import styled from "styled-components";
import { customOtherFeesOrder, getNameFromLink } from "utils/helper";
import { PriceView } from "components/assessor/doctor/EstimateCost";
import {
  Cell,
  ContentSection1,
  ContentSection8,
  ContentTag,
  ItemOtherForm,
  LabelSection1,
  SessionContainer,
  StText,
  TableBody,
  TableBodyRow,
  TableBodyRowTitle2,
  TableBodyRowValue2,
  TableBodyRowValueReason,
  TableFooter,
  TableFooterTitle,
  TableFooterValue,
  TableFooterValue3,
  TableHead,
  TableHeadItem,
  TitleSession,
} from "components/assessor/styled";
import Loading from "components/Loading";
import { CommentItem } from "components/assessor/claim/CommentItem";
import SimpleMentionEditor from "components/assessor/claim/EditorComment";
import * as antd from "antd";
import {
  COMMENT_MODE,
  APPROVED,
  REJECTED,
  CLARIFICATION_REQUIRED,
  CANCELLED,
  GST_RATE,
} from "constants/index";
import { useSelector } from "react-redux";
import { selectUserSelector } from "containers/Auth/authSlice";
import moment from "moment";
import { usePermissionClaim } from "hooks/usePermissionClaim";
import "./styles.css";
import { CASE_TAG, REMOVE_SURGEON_ADMIN_RELEASE_DATE } from "constants/epac.constans";
import { HighlightText } from "../sessionB.style";

const { TextArea } = Input;
const { Option } = Select;

type Props = {
  mentionDoctor: Array<MentionData>;
  claimStatus: any;
  assessorName: string;
  isPinHeader?: boolean;
  setModeComment?: any;
  modeComment?: any;
  setErrors?: any;
  errors?: any;
  dataSessionFormB?: any;
  approvedName?: string;
  errorsRequiredReason?: Array<any>;
  setErrorsRequiredReason?: any;
  isHistory?: boolean;
};

let sendingComment = false;

const SessionB = (
  {
    mentionDoctor,
    claimStatus,
    assessorName,
    setModeComment,
    modeComment,
    isPinHeader,
    setErrors,
    errors,
    dataSessionFormB,
    approvedName,
    errorsRequiredReason,
    setErrorsRequiredReason,
    isHistory = false,
  }: Props,
  dataSessionBRef: any
) => {
  const [data, setData] = useState<any>({});
  const { id } = useParams();
  const search = useLocation().search;
  const version_id =
    new URLSearchParams(search).get("versionId")?.toString() ?? "";
  const [listComments, setListComments] = useState<Array<any>>([]);
  const [tosps, setTosps] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUserSelector);
  const { isComment, isBlockEdit } = usePermissionClaim({
    claimStatus: claimStatus,
    user: user,
    isHistory,
  });
  const [rate, setRate] = useState({
    createdAt: "",
    id: "1",
    gst: GST_RATE,
    updatedAt: "",
  });
  const [isHideSurgeonAdmin, setIsHideSurgeonAdmin] = useState<boolean>(true);

  useEffect(() => {
    dataSessionBRef.current = data;

    if(data?.professional_fees) {
      // todo Need to update to real release date
      const removeSurgeonAdminReleaseDate = new Date(REMOVE_SURGEON_ADMIN_RELEASE_DATE);
      const createdAt = new Date(data.createdAt);
      const isHide = createdAt > removeSurgeonAdminReleaseDate;
      
      setIsHideSurgeonAdmin(isHide);
    }
  }, [data]);

  useEffect(() => {
    if (data?.id) {
      getListComments(data?.original_form_id || data?.id, modeComment == COMMENT_MODE.INTERNAL);
    }
  }, [data?.id, data?.doctor_name, modeComment]);

  useEffect(() => {
    if (dataSessionFormB) {
      (async () => {
        try {
          setLoading(true);
          getAllRate();
          await getListTosp(
            (dataSessionFormB?.professional_fees || []).map(
              (item: any) => item.tosp_id
            )
          );
          const newData = { ...dataSessionFormB };
          newData.final_bill_size = getTotalFinalBillSize(dataSessionFormB);
          newData.attendance_fees.final_stay_length = {
            ...(newData.attendance_fees.final_stay_length ??
              newData.attendance_fees.suggested_stay_length ??
              newData.attendance_fees.standard_stay_length),
          };
          setData((prev: any) => ({ ...(prev ?? {}), ...(newData || {}) }));
          dataSessionBRef.current = newData;
          if (newData.rate) {
            setRate(newData.rate);
          } else {
            getAllRate();
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      })();
    }
  }, [dataSessionFormB]);

  const getAllRate = async () => {
    try {
      const res = await DAService.rate.getAll();
      setRate(
        res?.data?.data?.items
          ? res?.data?.data?.items[0]
          : {
              createdAt: "",
              id: "1",
              gst: GST_RATE,
              updatedAt: "",
            }
      );
      setData((prev: any) => ({
        ...(prev ?? {}),
        rate: res?.data?.data?.items[0],
      }));
    } catch (error) {
      setRate({
        createdAt: "",
        id: "1",
        gst: GST_RATE,
        updatedAt: "",
      });
      console.log(error);
    }
  };

  const getListTosp = async (ids: Array<string>) => {
    try {
      const res = await DAService.assessor.filterTosp(ids);
      setTosps(res?.data?.data?.items || []);
    } catch (error) {
      console.log(error);
    }
  };

  const getTospCodeDescription = (idTosp: string) => {
    const tosp: any = tosps.find((item: any) => item.id === idTosp);
    if (!tosp) return "";
    return tosp.table_code + " - " + tosp.tosp_code + " - " + tosp.description;
  };

  const sendComment = async (field_name: string, content: string) => {
    if (sendingComment) {
      return;
    }
    sendingComment = true;

    const isInternal = modeComment == COMMENT_MODE.INTERNAL;
    const mode = isInternal ? modeComment: COMMENT_MODE.DRAFT;
      
    const dataComment = {
      version: data.version?.toString(),
      case_id: data?.case_id,
      form_name: "formB",
      form_id: data?.id,
      field_name,
      content,
      mode,
    };
    await DAService.assessor.sendComment(dataComment);
    getListComments(data?.original_form_id || data?.id, modeComment == COMMENT_MODE.INTERNAL);
    sendingComment = false;
  };

  const getListComments = async (form_id: string, isInternal: boolean) => {
    if (isHistory && !version_id) return;
    const res = !isHistory
      ? await DAService.assessor.getListComments(form_id, isInternal)
      : await DAService.historyPAC.getHistoryCaseListComments(
          version_id,
          form_id,
          isInternal
        );
    // sort comment
    const comments = (res?.data?.data || []).sort((a: any, b: any) => {
      const aTime = moment(a.createdAt).toDate().getTime();
      const bTime = moment(b.createdAt).toDate().getTime();
      return aTime > bTime ? 1 : aTime < bTime ? -1 : 0;
    });
    setListComments(comments);
  };

  const renderCommentByKeyName = (keyName: string) => {
    if (!listComments) return;
    const orgKeyName = keyName.split('|')[0];
    const comments = listComments?.filter(
      (item: any) => item.field_name === keyName || item.field_name === orgKeyName
    );

    return comments.map((comment: any) => {
      if (comment.mode == COMMENT_MODE.DRAFT && [
        CLARIFICATION_REQUIRED, APPROVED, REJECTED, CANCELLED
      ].includes(claimStatus?.valueOrigin)) comment = {
        ...comment, mode: COMMENT_MODE.DOCTOR // optimistic update
      }

      return <CommentItem
        form_id={data?.id}
        isDeleteComment={false}
        key={comment.id}
        comment={comment}
        id={comment.id}
        time={comment.createdAt}
        username={comment.user_name}
        content={comment.content}
        callback={() =>
          getListComments(data?.original_form_id || data?.id, modeComment == COMMENT_MODE.INTERNAL)
        }
      />
    });
  };

  const onChangeOtherFees = (
    e: any,
    type: string,
    keyName: string,
    keyName2: string,
    index: number,
    keyName3: string
  ) => {
    const newData = { ...data };
    let keyNameOrigin = keyName;
    if (keyName === "others") {
      keyNameOrigin = "immunotherapies";
    }
    if (keyName === "surgical_consumables") {
      keyNameOrigin = "consumable";
    }
    newData.other_fees = { ...(newData.other_fees || {}) };
    newData.other_fees[keyNameOrigin] = {
      ...(newData.other_fees[keyNameOrigin] || {}),
    };
    newData.other_fees[keyNameOrigin][keyName2] =
      [...newData.other_fees[keyNameOrigin][keyName2]] || [];
    newData.other_fees[keyNameOrigin][keyName2][index] = {
      ...(newData.other_fees[keyNameOrigin][keyName2][index] || {}),
    };
    switch (type) {
      case "number":
        newData.other_fees[keyNameOrigin][keyName2][index][keyName3] = e;
        break;
      case "checkbox":
        newData.other_fees[keyNameOrigin][keyName2][index][keyName3] =
          e.target.checked;
        break;
      default:
        return;
    }
    newData.final_bill_size = getTotalFinalBillSize(newData);
    newData.total_bill_size = getTotalBillSize(newData);
    setData(newData);
  };

  const onChangeInputText = (e: any, keyName: string, type: string) => {
    if (keyName === "assessment" || keyName === "ge_advice_concurrence") {
      setErrors({
        assessment: false,
        ge_advice_concurrence: false,
        message: "",
      });
    }
    const newData = { ...data };
    switch (type) {
      case "text":
        newData[keyName] = e.target.value;
        break;
      case "number":
        newData[keyName] = e;
        break;
      case "checkbox":
        newData[keyName] = e.target.checked;
        newData.final_hospital_charges = getHospitalCharges(
          "final_hospital_charges",
          newData
        );
        newData.total_hospital_charges = getHospitalCharges(
          "total_hospital_charges",
          newData
        );
        break;
      default:
        break;
    }
    newData.final_bill_size = getTotalFinalBillSize(newData);
    newData.total_bill_size = getTotalBillSize(newData);
    setData(newData);
  };

  const onChangeData = (
    e: any,
    type: string,
    index: number,
    targetb1: string,
    targetb2: string,
    targetb3: string
  ) => {
    const newData = { ...data };
    if (targetb1 === "professional_fees" && targetb3 === "disagree_reason") {
      if (targetb2 === "surgeon_fees") {
        if (setErrorsRequiredReason)
          setErrorsRequiredReason((list: any) => {
            return list?.map((it: any, id: number) => {
              if (id === index) {
                if (e?.target?.value?.trim()) {
                  it.surgeon_fees = false;
                } else {
                  it.surgeon_fees = true;
                }
              }
              return it;
            });
          });
      } else if (targetb2 === "assistant_surgeon_fees") {
        if (setErrorsRequiredReason)
          setErrorsRequiredReason((list: any) => {
            return list?.map((it: any, id: number) => {
              if (id === index) {
                if (e?.target?.value?.trim()) {
                  it.assistant_surgeon_fees = false;
                } else {
                  it.assistant_surgeon_fees = true;
                }
              }
              return it;
            });
          });
      } else if (targetb2 === "anaesthetist_fees") {
        if (setErrorsRequiredReason)
          setErrorsRequiredReason((list: any) => {
            return list?.map((it: any, id: number) => {
              if (id === index) {
                if (e?.target?.value?.trim()) {
                  it.anaesthetist_fees = false;
                } else {
                  it.anaesthetist_fees = true;
                }
              }
              return it;
            });
          });
      }
    }
    switch (type) {
      case "text":
        newData[targetb1][index][targetb2][targetb3] = e?.target?.value || "";
        break;
      case "number":
        newData[targetb1][index][targetb2][targetb3] = e;
        break;
      case "checkbox":
        newData[targetb1][index][targetb2][targetb3] = e.target.checked;
        break;
      default:
        return;
    }
    newData.final_bill_size = getTotalFinalBillSize(newData);
    newData.total_bill_size = getTotalBillSize(newData);
    setData(newData);
  };

  const onChangeAttendanceFeesData = (
    e: any,
    type: string,
    targetb1: string,
    targetb2: string,
    targetb3: string,
    targetb4: string
  ) => {
    const newData = { ...data };
    newData[targetb1][targetb2] = {
      ...(newData[targetb1][targetb2] || {}),
    };
    newData[targetb1][targetb2][targetb3] = {
      ...(newData[targetb1][targetb2][targetb3] || {}),
    };
    switch (type) {
      case "number":
        if (targetb4 === "no_of_days") {
          newData[targetb1][targetb2]["total_length_stay"] =
            e +
            (newData[targetb1][targetb2]["total_length_stay"] ?? 0) -
            (newData[targetb1][targetb2][targetb3]["no_of_days"] ?? 0);
        } else if (targetb4 === "total_no_of_days") {
          newData[targetb1][targetb2]["total_no_of_days"] =
            e +
            (newData[targetb1][targetb2]["total_no_of_days"] ?? 0) -
            (newData[targetb1][targetb2][targetb3]["total_no_of_days"] ?? 0);
        }
        newData[targetb1][targetb2][targetb3][targetb4] = e;
        break;
      case "checkbox":
        newData[targetb1][targetb2][targetb3][targetb4] = e.target.checked;
        break;
      default:
        break;
    }
    newData[targetb1][targetb2]["total_fee"] = getTotalAttendanceFee(
      newData,
      targetb2
    );
    newData.final_bill_size = getTotalFinalBillSize(newData);
    newData.total_bill_size = getTotalBillSize(newData);
    setData({ ...newData });
  };

  const getHospitalCharges = (keyname: string, dataSessionB: any) => {
    const gst = dataSessionB?.gst_hospital_charges
      ? 1 + rate?.gst / 100
      : 1 / (1 + rate?.gst / 100);
    return !isNaN(dataSessionB[keyname])
      ? (dataSessionB[keyname] ?? dataSessionB?.total_hospital_charges ?? 0) *
          gst
      : dataSessionB?.total_hospital_charges * gst;
  };

  const getTotalAttendanceFee = (dataSessionB: any, target: string) => {
    if (!dataSessionB?.attendance_fees?.hasOwnProperty(target)) return 0;
    const item1 = dataSessionB?.attendance_fees[target]?.general_ward || {};
    const item2 = dataSessionB?.attendance_fees[target]?.icu_ward || {};
    const item3 = dataSessionB?.attendance_fees[target]?.day_surgery || {};
    const valueItem1 = item1?.gst_included
      ? item1?.agreed_daily_fee * item1?.no_of_days * (1 + rate?.gst / 100) ?? 0
      : item1?.agreed_daily_fee * item1?.no_of_days ?? 0;
    const valueItem2 = item2?.gst_included
      ? item2?.agreed_daily_fee * item2?.no_of_days * (1 + rate?.gst / 100) ?? 0
      : item2?.agreed_daily_fee * item2?.no_of_days ?? 0;
    const valueItem3 = item3?.gst_included
      ? item3?.agreed_daily_fee * item3?.no_of_days * (1 + rate?.gst / 100) ?? 0
      : item3?.agreed_daily_fee * item3?.no_of_days ?? 0;
    const originaloVal1 = !isNaN(valueItem1) ? valueItem1 : 0;
    const originaloVal2 = !isNaN(valueItem2) ? valueItem2 : 0;
    const originaloVal3 = !isNaN(valueItem3) ? valueItem3 : 0;
    return originaloVal1 + originaloVal2 + originaloVal3;
  };

  const getTotalFinalBillSize = (dataSessionB: any) => {
    let final_bill_size = 0;
    final_bill_size += getTotalProfessionalFee(dataSessionB, true);
    final_bill_size += getTotalFinalOtherFee(dataSessionB);
    final_bill_size +=
      dataSessionB?.final_hospital_charges ??
      dataSessionB?.total_hospital_charges ??
      0;
    final_bill_size +=
      dataSessionB?.attendance_fees?.final_stay_length?.total_fee ??
      dataSessionB?.attendance_fees?.suggested_stay_length?.total_fee ??
      dataSessionB?.attendance_fees?.standard_stay_length?.total_fee ??
      0;
    return final_bill_size;
  };

  const getTotalBillSize = (dataSessionB: any) => {
    let total_bill_size = 0;
    total_bill_size += getTotalSugestProfessionalFee(dataSessionB, true);
    total_bill_size += getTotalSuggestOtherFee(dataSessionB);
    total_bill_size += dataSessionB?.total_hospital_charges ?? 0;
    total_bill_size +=
      dataSessionB?.attendance_fees?.suggested_stay_length?.total_fee ??
      dataSessionB?.attendance_fees?.standard_stay_length?.total_fee ??
      0;
    return total_bill_size;
  };

  const getTotalSuggestOtherFee = (dataSessionB: any) => {
    let total = 0;
    Object.entries(dataSessionB?.other_fees || {})?.forEach(
      ([key, value]: any, index) => {
        if (value?.enable) {
          value?.fee_items?.forEach((item: any) => {
            total += item?.gst_included
              ? (item?.unit_cost * item?.unit_no || 0) * (1 + rate?.gst / 100)
              : item?.unit_cost * item?.unit_no || 0;
          });
        }
      }
    );
    return total;
  };

  const getTotalFinalOtherFee = (dataSessionB: any) => {
    let total = 0;
    Object.entries(dataSessionB?.other_fees || {})?.forEach(
      ([key, value]: any, index) => {
        if (value?.enable) {
          value?.fee_items?.forEach((item: any) => {
            total += item?.gst_included
              ? (item?.final ?? item?.unit_cost * item?.unit_no ?? 0) *
                (1 + rate?.gst / 100)
              : item?.final ?? item?.unit_cost * item?.unit_no ?? 0;
          });
        }
      }
    );
    return total;
  };

  const getTotalStandardProfessionalFee = () => {
    let total = 0;
    data?.professional_fees?.map((value: any) => {
      total += value?.discarded
        ? 0
        : getTotalForItemProfessionalFee(value, "standard_fee");
    });
    return total;
  };

  const getTotalSugestProfessionalFee = (
    dataSessionB: any,
    checkDiscard = false
  ) => {
    let total = 0;
    dataSessionB?.professional_fees?.map((value: any) => {
      if (!checkDiscard) {
        total += getTotalForItemProfessionalFee(value, "suggest_fee");
      } else {
        total +=
          value?.discarded === true
            ? 0
            : getTotalForItemProfessionalFee(value, "suggest_fee");
      }
    });
    return total;
  };

  const getTotalForItemProfessionalFee = (value: any, keyName: string) => {
    const gst_fee = 1 + rate?.gst / 100;

    const gst_surgeon = value?.surgeon_fees?.gst_included ? gst_fee : 1;
    const value_surgeon_fees =
      (value?.surgeon_fees?.[keyName] ??
        value?.surgeon_fees?.suggest_fee ??
        value?.surgeon_fees?.standard_fee ??
        0) * gst_surgeon;

    const gst_assistant_surgeon = value?.assistant_surgeon_fees?.gst_included
      ? gst_fee
      : 1;
    const value_assistant_surgeon_fees =
      (value?.assistant_surgeon_fees?.[keyName] ??
        value?.assistant_surgeon_fees?.suggest_fee ??
        value?.assistant_surgeon_fees?.standard_fee ??
        0) * gst_assistant_surgeon;

    const gst_anaesthetist_fees = value?.anaesthetist_fees?.gst_included
      ? gst_fee
      : 1;
    const value_anaesthetist_fees =
      (value?.anaesthetist_fees?.[keyName] ??
        value?.anaesthetist_fees?.suggest_fee ??
        value?.anaesthetist_fees?.standard_fee ??
        0) * gst_anaesthetist_fees;

    return (
      value_surgeon_fees +
      (value?.assistant_surgeon ? value_assistant_surgeon_fees : 0) +
      (value?.sedation_require ? value_anaesthetist_fees : 0)
    );
  };

  const getTotalProfessionalFee = (dataSessionB: any, checkDiscard = false) => {
    let total = 0;
    dataSessionB?.professional_fees?.map((value: any) => {
      if (!checkDiscard) {
        total += getTotalForItemProfessionalFee(value, "final_fee");
      } else {
        total +=
          value?.discarded === true
            ? 0
            : getTotalForItemProfessionalFee(value, "final_fee");
      }
    });
    return total;
  };

  const onChangeRecommendation = (value: string) => {
    setData((dt: any) => ({ ...dt, recommendation: value }));
  };

  const nth = function (d: number) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const getFormBTagValue = (key: string, formB: any) => {
    if(formB?.case_tag && formB.case_tag[key])
    {
      return true;
    }
    return false;
  }

  const onChangeCaseTag= (e : any, field: string) => {
    let {case_tag} = data;
    if(!case_tag)
    {
      case_tag = {};
    }
    
    if (e?.target?.checked) {
      case_tag[field] = true;
    } else {
      case_tag[field] = false;
    }
    setData((dt: any) => ({ ...dt, case_tag: case_tag }));
  };

  const handleDiscard = (indexTosp: number) => {
    setData((dt: any) => {
      const professional_fees = dt.professional_fees.map(
        (item: any, index: number) => {
          if (indexTosp === index) {
            item.discarded = true;
          }
          return item;
        }
      );
      return { ...dt, professional_fees };
    });
  };
  const contentPopupConfirm = (index: number) => (
    <div className="flex justify-between items-center">
      <div className="text-base">Are you sure?</div>
      <div className="flex justify-between ml-2">
        <antd.Button
          onClick={() => handleDiscard(index)}
          size="small"
          className="text-base ml-2 px-0 py-2 font-bold rounded-lg flex items-center text-popup-confirm text-popup-confirm-ok"
          style={{ width: 50, height: 20 }}
        >
          Yes
        </antd.Button>
        <antd.Button
          danger
          size="small"
          className="text-base ml-2 px-0 py-2 font-bold rounded-lg flex items-center text-popup-confirm"
          style={{ width: 50, height: 20 }}
        >
          No
        </antd.Button>
      </div>
    </div>
  );

  const caseTagContent = (data: any) => {
    const caseTags: any = {};
    caseTags[CASE_TAG.APPROVE_PANEL_RATE] = 'Approve above panel rate';
    caseTags[CASE_TAG.NON_COMPLIANCE_FEES] = 'Non-compliance to fees';
    caseTags[CASE_TAG.REFER_NON_PANEL] = 'Refer non-panel';
    caseTags[CASE_TAG.TOSP_CODING] = 'TOSP coding';
    caseTags[CASE_TAG.CASE_ESC_DA] = 'Case escalation - DA Med Doctor';
    caseTags[CASE_TAG.CASE_ESC_GE] = 'Case escalation - GE';
    
    return Object.entries(caseTags).map(([key,label]) => (
      <Row key={key}>
        <Checkbox
          label={label as string}
          disable={isBlockEdit}
          checked={getFormBTagValue(key, data)}
          onChange={(e: any) => onChangeCaseTag(e, key)}
        />
      </Row>
    )
    );
  }

  return (
    <div className="assessment-session-b">
      {loading ? <Loading /> : null}
      {!isPinHeader && <Row justify="space-between"></Row>}
      <SpaceView size={44} />
      {/*01*/}
      <Row className="items-center relative">
        <Col span={21}>
          <SessionDivider />
        </Col>
        {/*<EditComponent />*/}
      </Row>
      <SessionContainer className="break-after">
        <Row>
          <Col span={24}>
            <TitleSession>Professional Fees</TitleSession>
          </Col>
        </Row>
        <SpaceView size={44} />
        {/*  */}
        {data?.professional_fees &&
          data?.professional_fees?.map((value: any, index: number) => (
            <>
              <Row
                className="pb-11"
                style={{
                  borderBottom: "1px solid #DDDDDD",
                }}
              >
                <Col
                  className={value?.discarded ? "tosp-discarded" : ""}
                  span={12}
                  style={{ paddingRight: "32px" }}
                >
                  <Row className="items-center">
                    <Space>
                      <Cell size={"48px"}>
                        <StText>
                          {index + 1} <span>{nth(index + 1)}</span>
                        </StText>
                      </Cell>
                      <div
                        style={{
                          minHeight: 50,
                          padding: "13px 5px",
                          borderRadius: "16px",
                          border: "1px solid #DDDDDD",
                          marginLeft: "8px",
                        }}
                      >
                        <ContentSection1 color="#101820">
                          {getTospCodeDescription(value?.tosp_id)}
                        </ContentSection1>
                      </div>
                      {(!isBlockEdit || value?.discarded) && (
                        <antd.Popover
                          content={contentPopupConfirm(index)}
                          trigger="focus"
                        >
                          <antd.Button
                            className="ml-4 button-discard"
                            size="small"
                          >
                            {value?.discarded ? "Discarded" : "Discard"}
                          </antd.Button>
                        </antd.Popover>
                      )}
                    </Space>
                  </Row>
                  <SpaceView size={24} />
                  <Row>
                    <LabelSection1>
                      Do you have the relevant diagnostic report(s) available for this patient?
                    </LabelSection1>
                    <SpaceView size={8} />
                    <Space>
                      <ContentTag
                        style={
                          !value?.patient_diagnosis_report ? StyleTagActive : {}
                        }
                      >
                        No
                      </ContentTag>
                      <ContentTag
                        className="ml-2"
                        style={
                          value?.patient_diagnosis_report ? StyleTagActive : {}
                        }
                      >
                        Yes
                      </ContentTag>
                    </Space>
                    {value?.patient_diagnosis_report_files?.map((file: any) => (
                      <>
                        <SpaceView size={8} />
                        <ContentTag style={{ backgroundColor: "#F3F7FC" }}>
                          {getNameFromLink(file)}
                        </ContentTag>
                      </>
                    ))}
                  </Row>
                  <SpaceView size={24} />
                  <Row>
                    <LabelSection1>
                      Does the procedure require anaesthesia (including sedation)?
                    </LabelSection1>
                    <SpaceView size={8} />
                    <Space>
                      <ContentTag
                        style={!value?.sedation_require ? StyleTagActive : {}}
                      >
                        No
                      </ContentTag>
                      <ContentTag
                        className="ml-2"
                        style={value?.sedation_require ? StyleTagActive : {}}
                      >
                        Yes
                      </ContentTag>
                    </Space>
                  </Row>
                  {
                    !isHideSurgeonAdmin && (
                      <>
                        <SpaceView size={24} />
                        <Row>
                          <LabelSection1>
                              Are you administering the anaesthesia (including sedation)?
                          </LabelSection1>
                          <SpaceView size={8} />
                          <Space>
                            <ContentTag
                              style={
                                !value?.sedation_performing ? StyleTagActive : {}
                              }
                            >
                              No
                            </ContentTag>
                            <ContentTag
                              className="ml-2"
                              style={value?.sedation_performing ? StyleTagActive : {}}
                            >
                              Yes
                            </ContentTag>
                          </Space>
                        </Row>
                      </>
                    )
                  }
                  <SpaceView size={24} />
                  <Row>
                    <LabelSection1>
                      Do you need assistant surgeon?
                    </LabelSection1>
                    <SpaceView size={8} />
                    <Space>
                      <ContentTag
                        style={!value?.assistant_surgeon ? StyleTagActive : {}}
                      >
                        No
                      </ContentTag>
                      <ContentTag
                        className="ml-2"
                        style={value?.assistant_surgeon ? StyleTagActive : {}}
                      >
                        Yes
                      </ContentTag>
                    </Space>
                  </Row>
                  <SpaceView size={24} />
                  <Row>
                    <Col span={24}>
                      <TableHead>
                        <TableHeadItem />
                        <TableHeadItem>Standard</TableHeadItem>
                        <TableHeadItem style={{ color: "#FE6B00" }}>
                          Suggested
                        </TableHeadItem>
                        <TableHeadItem style={{ color: "#01A2FF" }}>
                          Final
                        </TableHeadItem>
                        <TableHeadItem>GST</TableHeadItem>
                      </TableHead>
                      <TableBody style={{ backgroundColor: "#F3F7FC" }}>
                        <TableBodyRow>
                          <TableBodyRowTitle2>Surgeon fees</TableBodyRowTitle2>
                          <TableBodyRowValue2>
                            <PriceView>
                              {value?.surgeon_fees?.standard_fee}
                            </PriceView>
                          </TableBodyRowValue2>
                          <TableBodyRowValue2>
                            <PriceView color="#FE6B00">
                              {value?.surgeon_fees?.suggest_fee ??
                                value?.surgeon_fees?.standard_fee}
                            </PriceView>
                          </TableBodyRowValue2>
                          <TableBodyRowValue2 style={{ paddingRight: 0 }}>
                            <ItemOtherForm>
                              <InputPrice
                                disabled={isBlockEdit || value?.discarded}
                                style={{ width: "auto" }}
                                value={
                                  value?.surgeon_fees?.final_fee ??
                                  value?.surgeon_fees?.suggest_fee ??
                                  value?.surgeon_fees?.standard_fee
                                }
                                placeholder={"Input number"}
                                min={0}
                                onChange={(e: any) =>
                                  onChangeData(
                                    e,
                                    "number",
                                    index,
                                    "professional_fees",
                                    "surgeon_fees",
                                    "final_fee"
                                  )
                                }
                              />
                            </ItemOtherForm>
                          </TableBodyRowValue2>
                          <TableBodyRowValue2>
                            <Checkbox
                              disable={isBlockEdit || value?.discarded}
                              onChange={(e: any) =>
                                onChangeData(
                                  e,
                                  "checkbox",
                                  index,
                                  "professional_fees",
                                  "surgeon_fees",
                                  "gst_included"
                                )
                              }
                              checked={value?.surgeon_fees?.gst_included}
                            />
                          </TableBodyRowValue2>
                        </TableBodyRow>
                        {value?.surgeon_fees?.disagree_fee && (
                          <TableBodyRow>
                            <TableBodyRowValueReason
                              onChange={(e: any) =>
                                onChangeData(
                                  e,
                                  "text",
                                  index,
                                  "professional_fees",
                                  "surgeon_fees",
                                  "disagree_reason"
                                )
                              }
                              status={
                                errorsRequiredReason?.length &&
                                errorsRequiredReason[index]?.surgeon_fees &&
                                "error"
                              }
                              disabled={isBlockEdit || value?.discarded}
                              autoSize={{ minRows: 1 }}
                              placeholder={"State your reason*"}
                              value={value?.surgeon_fees?.disagree_reason || ""}
                            />
                          </TableBodyRow>
                        )}
                        <hr
                          style={{ borderColor: "#ffffff", marginLeft: "16px" }}
                        />
                        {/*  */}
                        {value?.assistant_surgeon && (
                          <>
                            <TableBodyRow>
                              <TableBodyRowTitle2>
                                Assistant surgeon fee
                              </TableBodyRowTitle2>
                              <TableBodyRowValue2>
                                <PriceView>
                                  {value?.assistant_surgeon_fees?.standard_fee}
                                </PriceView>
                              </TableBodyRowValue2>
                              <TableBodyRowValue2>
                                <PriceView color="#FE6B00">
                                  {value?.assistant_surgeon_fees?.suggest_fee ??
                                    value?.assistant_surgeon_fees?.standard_fee}
                                </PriceView>
                              </TableBodyRowValue2>
                              <TableBodyRowValue2 style={{ paddingRight: 0 }}>
                                <ItemOtherForm>
                                  <InputPrice
                                    disabled={isBlockEdit || value?.discarded}
                                    onChange={(e: any) =>
                                      onChangeData(
                                        e,
                                        "number",
                                        index,
                                        "professional_fees",
                                        "assistant_surgeon_fees",
                                        "final_fee"
                                      )
                                    }
                                    style={{ width: "auto" }}
                                    value={
                                      value?.assistant_surgeon_fees
                                        ?.final_fee ??
                                      value?.assistant_surgeon_fees
                                        ?.suggest_fee ??
                                      value?.assistant_surgeon_fees
                                        ?.standard_fee
                                    }
                                    placeholder={"Input number"}
                                    min={0}
                                  />
                                </ItemOtherForm>
                              </TableBodyRowValue2>
                              <TableBodyRowValue2>
                                <Checkbox
                                  disable={isBlockEdit || value?.discarded}
                                  checked={
                                    value?.assistant_surgeon_fees?.gst_included
                                  }
                                  onChange={(e: any) =>
                                    onChangeData(
                                      e,
                                      "checkbox",
                                      index,
                                      "professional_fees",
                                      "assistant_surgeon_fees",
                                      "gst_included"
                                    )
                                  }
                                />
                              </TableBodyRowValue2>
                            </TableBodyRow>
                            <TableBodyRow>
                              <TableBodyRowValueReason
                                onChange={(e: any) =>
                                  onChangeData(
                                    e,
                                    "text",
                                    index,
                                    "professional_fees",
                                    "assistant_surgeon_fees",
                                    "disagree_reason"
                                  )
                                }
                                status={
                                  errorsRequiredReason?.length &&
                                  errorsRequiredReason[index]
                                    ?.assistant_surgeon_fees &&
                                  "error"
                                }
                                disabled={isBlockEdit || value?.discarded}
                                autoSize={{ minRows: 1 }}
                                placeholder={"State your reason*"}
                                value={
                                  value?.assistant_surgeon_fees
                                    ?.disagree_reason || ""
                                }
                              />
                            </TableBodyRow>
                            <hr
                              style={{
                                borderColor: "#ffffff",
                                marginLeft: "16px",
                              }}
                            />
                          </>
                        )}
                        {value?.sedation_require && (
                          <>
                            <TableBodyRow>
                              <TableBodyRowTitle2>
                                Anaesthetist fees
                              </TableBodyRowTitle2>
                              <TableBodyRowValue2>
                                <PriceView>
                                  {value?.anaesthetist_fees?.standard_fee}
                                </PriceView>
                              </TableBodyRowValue2>
                              <TableBodyRowValue2>
                                <PriceView color="#FE6B00">
                                  {value?.anaesthetist_fees?.suggest_fee ??
                                    value?.anaesthetist_fees?.standard_fee}
                                </PriceView>
                              </TableBodyRowValue2>
                              <TableBodyRowValue2 style={{ paddingRight: 0 }}>
                                <ItemOtherForm>
                                  <InputPrice
                                    disabled={isBlockEdit || value?.discarded}
                                    style={{ width: "auto" }}
                                    value={
                                      value?.anaesthetist_fees?.final_fee ??
                                      value?.anaesthetist_fees?.suggest_fee ??
                                      value?.anaesthetist_fees?.standard_fee
                                    }
                                    placeholder={"Input number"}
                                    min={0}
                                    onChange={(e: any) =>
                                      onChangeData(
                                        e,
                                        "number",
                                        index,
                                        "professional_fees",
                                        "anaesthetist_fees",
                                        "final_fee"
                                      )
                                    }
                                  />
                                </ItemOtherForm>
                              </TableBodyRowValue2>
                              <TableBodyRowValue2>
                                <Checkbox
                                  disable={isBlockEdit || value?.discarded}
                                  checked={
                                    value?.anaesthetist_fees?.gst_included
                                  }
                                  onChange={(e: any) =>
                                    onChangeData(
                                      e,
                                      "checkbox",
                                      index,
                                      "professional_fees",
                                      "anaesthetist_fees",
                                      "gst_included"
                                    )
                                  }
                                />
                              </TableBodyRowValue2>
                            </TableBodyRow>
                            {value?.anaesthetist_fees?.disagree_fee && (
                              <TableBodyRow>
                                <TableBodyRowValueReason
                                  onChange={(e: any) =>
                                    onChangeData(
                                      e,
                                      "text",
                                      index,
                                      "professional_fees",
                                      "anaesthetist_fees",
                                      "disagree_reason"
                                    )
                                  }
                                  status={
                                    errorsRequiredReason?.length &&
                                    errorsRequiredReason[index]
                                      ?.anaesthetist_fees &&
                                    "error"
                                  }
                                  disabled={isBlockEdit}
                                  autoSize={{ minRows: 1 }}
                                  placeholder={"State your reason*"}
                                  value={
                                    value?.anaesthetist_fees?.disagree_reason ||
                                    ""
                                  }
                                />
                              </TableBodyRow>
                            )}

                            <hr
                              className="ml-4"
                              style={{ borderColor: "#ffffff" }}
                            />
                          </>
                        )}
                        {/*  */}
                      </TableBody>
                      {/* footer */}
                      <TableFooter
                        style={{
                          height: "56px !important",
                          boxShadow: "none",
                          backgroundColor: "#F3F7FC",
                        }}
                      >
                        <TableBodyRowTitle2 color="#1b1b1b">
                          Total of {index + 1}
                          {nth(index + 1)} TOSP code
                        </TableBodyRowTitle2>
                        <TableFooterValue>
                          <PriceView>
                            {getTotalForItemProfessionalFee(
                              value,
                              "standard_fee"
                            )}
                          </PriceView>
                        </TableFooterValue>
                        <TableFooterValue>
                          <PriceView color={"#FE6B00"}>
                            {getTotalForItemProfessionalFee(
                              value,
                              "suggest_fee"
                            )}
                          </PriceView>
                        </TableFooterValue>
                        <TableFooterValue style={{ color: "#01A2FF" }}>
                          <PriceView style={{ color: "#01A2FF" }}>
                            {getTotalForItemProfessionalFee(value, "final_fee")}
                          </PriceView>
                        </TableFooterValue>
                        <TableFooterValue></TableFooterValue>
                      </TableFooter>
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={12}
                  style={{
                    paddingLeft: "32px",
                    borderLeft: "1px solid #DDDDDD",
                  }}
                >
                  {renderCommentByKeyName(`${value.tosp_id}|${index}`)}
                  <SpaceView size={10} />
                  {isComment && (
                    <SimpleMentionEditor
                      mentions={mentionDoctor}
                      onSubmit={(content: string) =>
                        sendComment(`${value.tosp_id}|${index}`, content)
                      }
                    />
                  )}
                </Col>
              </Row>
              <SpaceView size={28} />
            </>
          ))}
        {/*  */}
        <SpaceView size={28} />
        <Row>
          <TableFooter color="#55BED2" style={{ height: 92 }}>
            <TableFooterTitle>Total Professional Fees:</TableFooterTitle>
            <TableFooterValue>
              <ContentSection1 color="#101820">Standard</ContentSection1>
              <SpaceView size={4} />
              <PriceView>{getTotalStandardProfessionalFee()}</PriceView>
            </TableFooterValue>
            <TableFooterValue>
              <ContentSection1 color="#FE6B00">Suggested</ContentSection1>
              <SpaceView size={4} />
              <PriceView color="#FE6B00">
                {getTotalSugestProfessionalFee(data, true)}
              </PriceView>
            </TableFooterValue>
            <TableFooterValue>
              <ContentSection1 color="#01A2FF">Final</ContentSection1>
              <SpaceView size={4} />
              <PriceView color="#01A2FF">
                {getTotalProfessionalFee(data, true)}
              </PriceView>
            </TableFooterValue>
          </TableFooter>
        </Row>
      </SessionContainer>
      <SpaceView size={44} />
      {/*02*/}
      <Row style={{ alignItems: "center", position: "relative" }}>
        <Col span={21}>
          <SessionDivider value={{ index: 2 }} />
        </Col>
        {/*<EditComponent />*/}
      </Row>
      <SessionContainer>
        <Row>
          <Col span={24}>
            <TitleSession>Attendance Fees</TitleSession>
          </Col>
        </Row>
        <SpaceView size={44} />
        <Row>
          <Col span={12} style={{ paddingRight: "32px" }}>
            <Row className="">
              <LabelSection1>Standard Length of Stay</LabelSection1>
              <SpaceView size={24} />
              <Col span={24}>
                <div>
                  <TableHead>
                    <TableHeadItem />
                    <TableHeadItem>Total No. of Visits</TableHeadItem>
                    <TableHeadItem style={{ paddingRight: 0 }}>
                      Panel Fee
                    </TableHeadItem>
                    <TableHeadItem>Total No. of Days</TableHeadItem>
                    <TableHeadItem style={{ paddingRight: "36px" }}>
                      GST?
                    </TableHeadItem>
                  </TableHead>
                  <SpaceView size={16} />
                  <TableBody style={{ backgroundColor: "#F3F7FC" }}>
                    <TableBodyRow>
                      <TableBodyRowTitle2>General Ward</TableBodyRowTitle2>
                      <TableBodyRowValue2 style={{ paddingRight: "16px" }}>
                        {
                          data?.attendance_fees?.standard_stay_length
                            ?.general_ward?.no_of_days
                        }
                      </TableBodyRowValue2>
                      <TableBodyRowValue2 style={{ paddingRight: 0 }}>
                        <PriceView>
                          {
                            data?.attendance_fees?.standard_stay_length
                              ?.general_ward?.agreed_daily_fee
                          }
                        </PriceView>
                      </TableBodyRowValue2>
                      <TableBodyRowValue2 style={{ paddingRight: "16px" }}>
                        {
                          data?.attendance_fees?.standard_stay_length
                            ?.general_ward?.total_no_of_days
                        }
                      </TableBodyRowValue2>
                      <TableBodyRowValue2>
                        <TableBodyRowValue2>
                          {data?.attendance_fees?.standard_stay_length
                            ?.general_ward?.gst_included
                            ? "Yes"
                            : "No"}
                        </TableBodyRowValue2>
                      </TableBodyRowValue2>
                    </TableBodyRow>
                    <hr
                      style={{ borderColor: "#ffffff", marginLeft: "16px" }}
                    />
                    <TableBodyRow>
                      <TableBodyRowTitle2>ICU / HDU Ward</TableBodyRowTitle2>
                      <TableBodyRowValue2 style={{ paddingRight: "16px" }}>
                        {
                          data?.attendance_fees?.standard_stay_length?.icu_ward
                            ?.no_of_days
                        }
                      </TableBodyRowValue2>
                      <TableBodyRowValue2 style={{ paddingRight: 0 }}>
                        <PriceView>
                          {
                            data?.attendance_fees?.standard_stay_length
                              ?.icu_ward?.agreed_daily_fee
                          }
                        </PriceView>
                      </TableBodyRowValue2>
                      <TableBodyRowValue2 style={{ paddingRight: "16px" }}>
                        {
                          data?.attendance_fees?.standard_stay_length?.icu_ward
                            ?.total_no_of_days
                        }
                      </TableBodyRowValue2>
                      <TableBodyRowValue2>
                        <TableBodyRowValue2>
                          {data?.attendance_fees?.standard_stay_length?.icu_ward
                            ?.gst_included
                            ? "Yes"
                            : "No"}
                        </TableBodyRowValue2>
                      </TableBodyRowValue2>
                    </TableBodyRow>
                    <hr
                      style={{ borderColor: "#ffffff", marginLeft: "16px" }}
                    />

                    <TableBodyRow>
                      <TableBodyRowTitle2>Day Surgery</TableBodyRowTitle2>
                      <TableBodyRowValue2 style={{ paddingRight: "16px" }}>
                        {
                          data?.attendance_fees?.standard_stay_length
                            ?.day_surgery?.no_of_days
                        }
                      </TableBodyRowValue2>
                      <TableBodyRowValue2 style={{ paddingRight: 0 }}>
                        <PriceView>
                          {
                            data?.attendance_fees?.standard_stay_length
                              ?.day_surgery?.agreed_daily_fee
                          }
                        </PriceView>
                      </TableBodyRowValue2>
                      <TableBodyRowValue2 style={{ paddingRight: "16px" }}>
                        {
                          data?.attendance_fees?.standard_stay_length
                            ?.day_surgery?.total_no_of_days
                        }
                      </TableBodyRowValue2>
                      <TableBodyRowValue2>
                        <TableBodyRowValue2>
                          {data?.attendance_fees?.standard_stay_length
                            ?.day_surgery?.gst_included
                            ? "Yes"
                            : "No"}
                        </TableBodyRowValue2>
                      </TableBodyRowValue2>
                    </TableBodyRow>
                    <hr
                      style={{ borderColor: "#ffffff", marginLeft: "16px" }}
                    />
                    {/*  */}
                  </TableBody>
                  <SpaceView size={16} />
                  <TableFooter
                    style={{
                      height: "56px !important",
                      boxShadow: "none",
                      backgroundColor: "#F3F7FC",
                    }}
                  >
                    <TableFooterValue3>Total Length of Stay</TableFooterValue3>
                    <TableFooterValue3>
                      {
                        data?.attendance_fees?.standard_stay_length
                          ?.total_no_of_days
                      }
                    </TableFooterValue3>
                    <TableFooterValue3></TableFooterValue3>
                    <TableFooterValue3>
                      <PriceView>
                        {getTotalAttendanceFee(data, "standard_stay_length")}
                      </PriceView>
                    </TableFooterValue3>
                  </TableFooter>
                </div>
              </Col>
            </Row>
            {/*  */}
            <SpaceView size={44} />
            {data?.attendance_fees?.disagree_fee && (
              <Row>
                <LabelSection1>Your Suggested Length of Stay</LabelSection1>
                <SpaceView size={24} />
                <Col span={24}>
                  <div>
                    <TableHead>
                      <TableHeadItem />
                      <TableHeadItem>Total No. of Visits</TableHeadItem>
                      <TableHeadItem>Panel Fee</TableHeadItem>
                      <TableHeadItem>Total No. of Days</TableHeadItem>
                      <TableHeadItem>GST?</TableHeadItem>
                    </TableHead>
                    <SpaceView size={16} />
                    <TableBody style={{ backgroundColor: "#FFF0E6" }}>
                      <TableBodyRow>
                        <TableBodyRowTitle2>General Ward</TableBodyRowTitle2>
                        <TableBodyRowValue2>
                          {data?.attendance_fees?.suggested_stay_length
                            ?.general_ward?.no_of_days ??
                            data?.attendance_fees?.standard_stay_length
                              ?.general_ward?.no_of_days}
                        </TableBodyRowValue2>
                        <TableBodyRowValue2 style={{ paddingRight: 0 }}>
                          <PriceView>
                            {data?.attendance_fees?.suggested_stay_length
                              ?.general_ward?.agreed_daily_fee ??
                              data?.attendance_fees?.standard_stay_length
                                ?.general_ward?.agreed_daily_fee}
                          </PriceView>
                        </TableBodyRowValue2>
                        <TableBodyRowValue2>
                          {data?.attendance_fees?.suggested_stay_length
                            ?.general_ward?.total_no_of_days ??
                            data?.attendance_fees?.standard_stay_length
                              ?.general_ward?.total_no_of_days}
                        </TableBodyRowValue2>
                        <TableBodyRowValue2>
                          <TableBodyRowValue2>
                            {data?.attendance_fees?.suggested_stay_length
                              ?.general_ward?.gst_included
                              ? "Yes"
                              : "No"}
                          </TableBodyRowValue2>
                        </TableBodyRowValue2>
                      </TableBodyRow>
                      <hr
                        style={{ borderColor: "#ffffff", marginLeft: "16px" }}
                      />
                      <TableBodyRow>
                        <TableBodyRowTitle2>ICU / HDU Ward</TableBodyRowTitle2>
                        <TableBodyRowValue2>
                          {data?.attendance_fees?.suggested_stay_length
                            ?.icu_ward?.no_of_days ??
                            data?.attendance_fees?.standard_stay_length
                              ?.icu_ward?.no_of_days}
                        </TableBodyRowValue2>
                        <TableBodyRowValue2 style={{ paddingRight: 0 }}>
                          <PriceView>
                            {data?.attendance_fees?.suggested_stay_length
                              ?.icu_ward?.agreed_daily_fee ??
                              data?.attendance_fees?.standard_stay_length
                                ?.icu_ward?.agreed_daily_fee}
                          </PriceView>
                        </TableBodyRowValue2>
                        <TableBodyRowValue2>
                          {data?.attendance_fees?.suggested_stay_length
                            ?.icu_ward?.total_no_of_days ??
                            data?.attendance_fees?.standard_stay_length
                              ?.icu_ward?.total_no_of_days}
                        </TableBodyRowValue2>
                        <TableBodyRowValue2>
                          <TableBodyRowValue2>
                            {data?.attendance_fees?.suggested_stay_length
                              ?.icu_ward?.gst_included
                              ? "Yes"
                              : "No"}
                          </TableBodyRowValue2>
                        </TableBodyRowValue2>
                      </TableBodyRow>
                      <hr
                        style={{ borderColor: "#ffffff", marginLeft: "16px" }}
                      />

                      <TableBodyRow>
                        <TableBodyRowTitle2>Day Surgery</TableBodyRowTitle2>
                        <TableBodyRowValue2>
                          {data?.attendance_fees?.suggested_stay_length
                            ?.day_surgery?.no_of_days ??
                            data?.attendance_fees?.standard_stay_length
                              ?.day_surgery?.no_of_days}
                        </TableBodyRowValue2>
                        <TableBodyRowValue2 style={{ paddingRight: 0 }}>
                          <PriceView>
                            {data?.attendance_fees?.suggested_stay_length
                              ?.day_surgery?.agreed_daily_fee ??
                              data?.attendance_fees?.standard_stay_length
                                ?.day_surgery?.agreed_daily_fee}
                          </PriceView>
                        </TableBodyRowValue2>
                        <TableBodyRowValue2>
                          {data?.attendance_fees?.suggested_stay_length
                            ?.day_surgery?.total_no_of_days ??
                            data?.attendance_fees?.standard_stay_length
                              ?.day_surgery?.total_no_of_days}
                        </TableBodyRowValue2>
                        <TableBodyRowValue2>
                          <TableBodyRowValue2>
                            {data?.attendance_fees?.suggested_stay_length
                              ?.day_surgery?.gst_included
                              ? "Yes"
                              : "No"}
                          </TableBodyRowValue2>
                        </TableBodyRowValue2>
                      </TableBodyRow>
                      <hr
                        style={{ borderColor: "#ffffff", marginLeft: "16px" }}
                      />
                      {/*  */}
                    </TableBody>
                    <SpaceView size={16} />
                    <TableFooter
                      style={{
                        height: "56px !important",
                        boxShadow: "none",
                        backgroundColor: "#FFF0E6",
                      }}
                    >
                      <TableFooterValue3>
                        Total Length of Stay
                      </TableFooterValue3>
                      <TableFooterValue3>
                        {data?.attendance_fees?.suggested_stay_length
                          ?.total_no_of_days ??
                          data?.attendance_fees?.standard_stay_length
                            ?.total_no_of_days}
                      </TableFooterValue3>
                      <TableFooterValue3></TableFooterValue3>
                      <TableFooterValue3>
                        <PriceView>
                          {getTotalAttendanceFee(
                            data,
                            "suggested_stay_length"
                          ) ??
                            getTotalAttendanceFee(data, "standard_stay_length")}
                        </PriceView>
                      </TableFooterValue3>
                    </TableFooter>
                  </div>
                </Col>
              </Row>
            )}

            {/*  */}
            <SpaceView size={24} />
            <Row className="break-before">
              <LabelSection1>Final Length of Stay</LabelSection1>
              <SpaceView size={24} />
              <Col span={24}>
                <div>
                  <TableHead>
                    <TableHeadItem />
                    <TableHeadItem>Total No. of Visits</TableHeadItem>
                    <TableHeadItem style={{ paddingRight: 0 }}>
                      Panel Fee
                    </TableHeadItem>
                    <TableHeadItem>Total No. of Days</TableHeadItem>
                    <TableHeadItem style={{ paddingRight: "36px" }}>
                      GST?
                    </TableHeadItem>
                  </TableHead>
                  <SpaceView size={16} />
                  <TableBody style={{ backgroundColor: "#EDFBFC" }}>
                    <TableBodyRow>
                      <TableBodyRowTitle2>General Ward</TableBodyRowTitle2>
                      <TableBodyRowValue2 style={{ paddingRight: "16px" }}>
                        <ItemOtherForm>
                          <InputPrice
                            disabled={isBlockEdit}
                            isNotIcon
                            style={{ width: 50 }}
                            min={0}
                            value={
                              data?.attendance_fees?.final_stay_length
                                ?.general_ward?.no_of_days ??
                              data?.attendance_fees?.suggested_stay_length
                                ?.general_ward?.no_of_days ??
                              data?.attendance_fees?.standard_stay_length
                                ?.general_ward?.no_of_days ??
                              0
                            }
                            onChange={(e: any) =>
                              onChangeAttendanceFeesData(
                                e,
                                "number",
                                "attendance_fees",
                                "final_stay_length",
                                "general_ward",
                                "no_of_days"
                              )
                            }
                          />
                        </ItemOtherForm>
                      </TableBodyRowValue2>
                      <TableBodyRowValue2 style={{ paddingRight: 0 }}>
                        <ItemOtherForm>
                          <InputPrice
                            disabled={isBlockEdit}
                            style={{ width: "auto" }}
                            min={0}
                            value={
                              data?.attendance_fees?.final_stay_length
                                ?.general_ward?.agreed_daily_fee ??
                              data?.attendance_fees?.suggested_stay_length
                                ?.general_ward?.agreed_daily_fee ??
                              data?.attendance_fees?.standard_stay_length
                                ?.general_ward?.agreed_daily_fee ??
                              0
                            }
                            onChange={(e: any) =>
                              onChangeAttendanceFeesData(
                                e,
                                "number",
                                "attendance_fees",
                                "final_stay_length",
                                "general_ward",
                                "agreed_daily_fee"
                              )
                            }
                          />
                        </ItemOtherForm>
                      </TableBodyRowValue2>
                      <TableBodyRowValue2 style={{ paddingRight: "16px" }}>
                        <ItemOtherForm>
                          <InputPrice
                            disabled={isBlockEdit}
                            isNotIcon
                            style={{ width: 50 }}
                            min={0}
                            value={
                              data?.attendance_fees?.final_stay_length
                                ?.general_ward?.total_no_of_days ??
                              data?.attendance_fees?.suggested_stay_length
                                ?.general_ward?.total_no_of_days ??
                              data?.attendance_fees?.standard_stay_length
                                ?.general_ward?.total_no_of_days ??
                              0
                            }
                            onChange={(e: any) =>
                              onChangeAttendanceFeesData(
                                e,
                                "number",
                                "attendance_fees",
                                "final_stay_length",
                                "general_ward",
                                "total_no_of_days"
                              )
                            }
                          />
                        </ItemOtherForm>
                      </TableBodyRowValue2>
                      <TableBodyRowValue2>
                        <TableBodyRowValue2>
                          <Checkbox
                            disable={isBlockEdit}
                            checked={
                              !!data?.attendance_fees?.final_stay_length
                                ?.general_ward?.gst_included
                            }
                            onChange={(e: any) =>
                              onChangeAttendanceFeesData(
                                e,
                                "checkbox",
                                "attendance_fees",
                                "final_stay_length",
                                "general_ward",
                                "gst_included"
                              )
                            }
                          />
                        </TableBodyRowValue2>
                      </TableBodyRowValue2>
                    </TableBodyRow>
                    <hr
                      style={{ borderColor: "#ffffff", marginLeft: "16px" }}
                    />
                    <TableBodyRow>
                      <TableBodyRowTitle2>ICU / HDU Ward</TableBodyRowTitle2>
                      <TableBodyRowValue2 style={{ paddingRight: "16px" }}>
                        <ItemOtherForm>
                          <InputPrice
                            disabled={isBlockEdit}
                            isNotIcon
                            style={{ width: 50 }}
                            min={0}
                            value={
                              data?.attendance_fees?.final_stay_length?.icu_ward
                                ?.no_of_days ??
                              data?.attendance_fees?.suggested_stay_length
                                ?.icu_ward?.no_of_days ??
                              data?.attendance_fees?.standard_stay_length
                                ?.icu_ward?.no_of_days ??
                              0
                            }
                            onChange={(e: any) =>
                              onChangeAttendanceFeesData(
                                e,
                                "number",
                                "attendance_fees",
                                "final_stay_length",
                                "icu_ward",
                                "no_of_days"
                              )
                            }
                          />
                        </ItemOtherForm>
                      </TableBodyRowValue2>
                      <TableBodyRowValue2 style={{ paddingRight: 0 }}>
                        <ItemOtherForm>
                          <InputPrice
                            disabled={isBlockEdit}
                            style={{ width: "auto" }}
                            min={0}
                            value={
                              data?.attendance_fees?.final_stay_length?.icu_ward
                                ?.agreed_daily_fee ??
                              data?.attendance_fees?.suggested_stay_length
                                ?.icu_ward?.agreed_daily_fee ??
                              data?.attendance_fees?.standard_stay_length
                                ?.icu_ward?.agreed_daily_fee ??
                              0
                            }
                            onChange={(e: any) =>
                              onChangeAttendanceFeesData(
                                e,
                                "number",
                                "attendance_fees",
                                "final_stay_length",
                                "icu_ward",
                                "agreed_daily_fee"
                              )
                            }
                          />
                        </ItemOtherForm>
                      </TableBodyRowValue2>
                      <TableBodyRowValue2 style={{ paddingRight: "16px" }}>
                        <ItemOtherForm>
                          <InputPrice
                            disabled={isBlockEdit}
                            isNotIcon
                            style={{ width: 50 }}
                            min={0}
                            value={
                              data?.attendance_fees?.final_stay_length?.icu_ward
                                ?.total_no_of_days ??
                              data?.attendance_fees?.suggested_stay_length
                                ?.icu_ward?.total_no_of_days ??
                              data?.attendance_fees?.standard_stay_length
                                ?.icu_ward?.total_no_of_days ??
                              0
                            }
                            onChange={(e: any) =>
                              onChangeAttendanceFeesData(
                                e,
                                "number",
                                "attendance_fees",
                                "final_stay_length",
                                "icu_ward",
                                "total_no_of_days"
                              )
                            }
                          />
                        </ItemOtherForm>
                      </TableBodyRowValue2>
                      <TableBodyRowValue2>
                        <TableBodyRowValue2>
                          <Checkbox
                            disable={isBlockEdit}
                            checked={
                              !!data?.attendance_fees?.final_stay_length
                                ?.icu_ward?.gst_included
                            }
                            onChange={(e: any) =>
                              onChangeAttendanceFeesData(
                                e,
                                "checkbox",
                                "attendance_fees",
                                "final_stay_length",
                                "icu_ward",
                                "gst_included"
                              )
                            }
                          />
                        </TableBodyRowValue2>
                      </TableBodyRowValue2>
                    </TableBodyRow>
                    <hr
                      style={{ borderColor: "#ffffff", marginLeft: "16px" }}
                    />

                    <TableBodyRow>
                      <TableBodyRowTitle2>Day Surgery</TableBodyRowTitle2>
                      <TableBodyRowValue2 style={{ paddingRight: "16px" }}>
                        <ItemOtherForm>
                          <InputPrice
                            disabled={isBlockEdit}
                            isNotIcon
                            style={{ width: 50 }}
                            min={0}
                            value={
                              data?.attendance_fees?.final_stay_length
                                ?.day_surgery?.no_of_days ??
                              data?.attendance_fees?.suggested_stay_length
                                ?.day_surgery?.no_of_days ??
                              data?.attendance_fees?.standard_stay_length
                                ?.day_surgery?.no_of_days ??
                              0
                            }
                            onChange={(e: any) =>
                              onChangeAttendanceFeesData(
                                e,
                                "number",
                                "attendance_fees",
                                "final_stay_length",
                                "day_surgery",
                                "no_of_days"
                              )
                            }
                          />
                        </ItemOtherForm>
                      </TableBodyRowValue2>
                      <TableBodyRowValue2 style={{ paddingRight: 0 }}>
                        <ItemOtherForm>
                          <InputPrice
                            disabled={isBlockEdit}
                            style={{ width: "auto" }}
                            min={0}
                            value={
                              data?.attendance_fees?.final_stay_length
                                ?.day_surgery?.agreed_daily_fee ??
                              data?.attendance_fees?.suggested_stay_length
                                ?.day_surgery?.agreed_daily_fee ??
                              data?.attendance_fees?.standard_stay_length
                                ?.day_surgery?.agreed_daily_fee ??
                              0
                            }
                            onChange={(e: any) =>
                              onChangeAttendanceFeesData(
                                e,
                                "number",
                                "attendance_fees",
                                "final_stay_length",
                                "day_surgery",
                                "agreed_daily_fee"
                              )
                            }
                          />
                        </ItemOtherForm>
                      </TableBodyRowValue2>
                      <TableBodyRowValue2 style={{ paddingRight: "16px" }}>
                        <ItemOtherForm>
                          <InputPrice
                            disabled={isBlockEdit}
                            isNotIcon
                            style={{ width: 50 }}
                            min={0}
                            value={
                              data?.attendance_fees?.final_stay_length
                                ?.day_surgery?.total_no_of_days ??
                              data?.attendance_fees?.suggested_stay_length
                                ?.day_surgery?.total_no_of_days ??
                              data?.attendance_fees?.standard_stay_length
                                ?.day_surgery?.total_no_of_days ??
                              0
                            }
                            onChange={(e: any) =>
                              onChangeAttendanceFeesData(
                                e,
                                "number",
                                "attendance_fees",
                                "final_stay_length",
                                "day_surgery",
                                "total_no_of_days"
                              )
                            }
                          />
                        </ItemOtherForm>
                      </TableBodyRowValue2>
                      <TableBodyRowValue2>
                        <TableBodyRowValue2>
                          <Checkbox
                            disable={isBlockEdit}
                            checked={
                              !!data?.attendance_fees?.final_stay_length
                                ?.day_surgery?.gst_included
                            }
                            onChange={(e: any) =>
                              onChangeAttendanceFeesData(
                                e,
                                "checkbox",
                                "attendance_fees",
                                "final_stay_length",
                                "day_surgery",
                                "gst_included"
                              )
                            }
                          />
                        </TableBodyRowValue2>
                      </TableBodyRowValue2>
                    </TableBodyRow>
                    <hr
                      style={{ borderColor: "#ffffff", marginLeft: "16px" }}
                    />

                    {/*  */}
                  </TableBody>
                  <SpaceView size={16} />
                  <TableFooter
                    style={{
                      height: "56px !important",
                      boxShadow: "none",
                      backgroundColor: "#EDFBFC",
                    }}
                  >
                    <TableFooterValue3>Total Length of Stay</TableFooterValue3>
                    <TableFooterValue3>
                      {data?.attendance_fees?.final_stay_length
                        ?.total_no_of_days ??
                        data?.attendance_fees?.suggested_stay_length
                          ?.total_no_of_days ??
                        data?.attendance_fees?.standard_stay_length
                          ?.total_no_of_days}
                    </TableFooterValue3>
                    <TableFooterValue3>
                      <PriceView>
                        {getTotalAttendanceFee(data, "final_stay_length") ??
                          getTotalAttendanceFee(
                            data,
                            "suggested_stay_length"
                          ) ??
                          getTotalAttendanceFee(data, "standard_stay_length")}
                      </PriceView>
                    </TableFooterValue3>
                  </TableFooter>
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            span={12}
            style={{ paddingLeft: "32px", borderLeft: "1px solid #DDDDDD" }}
          >
            {renderCommentByKeyName("attendance_fees")}
            <SpaceView size={10} />
            {isComment && (
              <SimpleMentionEditor
                mentions={mentionDoctor}
                onSubmit={(content: string) =>
                  sendComment("attendance_fees", content)
                }
              />
            )}
          </Col>
        </Row>
        <SpaceView size={28} />
        <Row>
          <TableFooter color="#55BED2" style={{ height: 92 }}>
            <TableFooterTitle>Total Attendance Fees:</TableFooterTitle>
            <TableFooterValue>
              <ContentSection1 color="#101820">Standard</ContentSection1>
              <SpaceView size={4} />
              <PriceView>
                {getTotalAttendanceFee(data, "standard_stay_length")}
              </PriceView>
            </TableFooterValue>
            <TableFooterValue>
              <ContentSection1 color="#FE6B00">Suggested</ContentSection1>
              <SpaceView size={4} />
              <PriceView color="#FE6B00">
                {getTotalAttendanceFee(data, "suggested_stay_length") ??
                  getTotalAttendanceFee(data, "standard_stay_length")}
              </PriceView>
            </TableFooterValue>
            <TableFooterValue>
              <ContentSection1 color="#01A2FF">Final</ContentSection1>
              <SpaceView size={4} />
              <PriceView color="#01A2FF">
                {getTotalAttendanceFee(data, "final_stay_length") ??
                  getTotalAttendanceFee(data, "suggested_stay_length") ??
                  getTotalAttendanceFee(data, "standard_stay_length")}
              </PriceView>
            </TableFooterValue>
          </TableFooter>
        </Row>
      </SessionContainer>
      {/*03*/}
      <SpaceView size={44} />
      <Row style={{ alignItems: "center", position: "relative" }}>
        <Col span={21}>
          <SessionDivider value={{ index: 3 }} />
        </Col>
        {/*<EditComponent />*/}
      </Row>
      <SessionContainer>
        <Row>
          <Col span={24}>
            <TitleSession>
                Item(s) charged under Letter of Certification (LC)
            </TitleSession>
            <HighlightText>
                Please only include itemized charges that will be charged under Doctor's LC.
                <br />
                We will not be able to review if itemized breakdown is not given.
                <br />
                Do not include items provided by hospital.
            </HighlightText>
          </Col>
        </Row>
        <SpaceView size={90} />
        {data?.other_fees &&
          Object.entries(data?.other_fees).length &&
          Object.entries(customOtherFeesOrder(data?.other_fees)).map(
            ([key, value]: any, index: number) => {
              if (value.enable && value?.fee_items?.length) {
                return (
                  <>
                    <SpaceView size={44} />
                    <Row
                      style={{
                        paddingBottom: "60px",
                        borderBottom: "1px solid #DDDDDD",
                      }}
                    >
                      <Col span={12} style={{ paddingRight: "16px" }}>
                        <Row>
                          <LabelSection1
                            style={{ textTransform: "capitalize" }}
                          >
                            {key.split("_").join(" ")}
                          </LabelSection1>
                          <SpaceView size={24} />
                          <Col span={24}>
                            <div>
                              <TableHead>
                                <TableHeadItem />
                                {key !== "significant_investigations" ? (
                                  <TableHeadItem>Dosage</TableHeadItem>
                                ) : null}
                                <TableHeadItem>No. of units</TableHeadItem>
                                <TableHeadItem>Unit Cost</TableHeadItem>
                                <TableHeadItem>GST?</TableHeadItem>
                                <TableHeadItem color="#FE6B00">
                                  Suggested
                                </TableHeadItem>
                                <TableHeadItem color="#01A2FF">
                                  Final
                                </TableHeadItem>
                              </TableHead>
                              <SpaceView size={16} />
                              <TableBody style={{ backgroundColor: "#F3F7FC" }}>
                                {value?.fee_items.length
                                  ? value?.fee_items?.map(
                                      (item: any, indexItem: number) => (
                                        <>
                                          <TableBodyRow>
                                            <TableBodyRowTitle2>
                                              {item?.description}
                                            </TableBodyRowTitle2>
                                            {key !==
                                            "significant_investigations" ? (
                                              <TableBodyRowValue2>
                                                <ContentSection1 color="#101820">
                                                  {item?.brand}
                                                </ContentSection1>
                                              </TableBodyRowValue2>
                                            ) : null}
                                            <TableBodyRowValue2>
                                              <ContentSection1 color="#101820">
                                                {item?.unit_no}
                                              </ContentSection1>
                                            </TableBodyRowValue2>
                                            <TableBodyRowValue2>
                                              <ContentSection1 color="#101820">
                                                <PriceView>
                                                  {item?.unit_cost}
                                                </PriceView>
                                              </ContentSection1>
                                            </TableBodyRowValue2>
                                            <TableBodyRowValue2>
                                              <Checkbox
                                                disable={isBlockEdit}
                                                checked={!!item?.gst_included}
                                                onChange={(e: any) =>
                                                  onChangeOtherFees(
                                                    e,
                                                    "checkbox",
                                                    key,
                                                    "fee_items",
                                                    indexItem,
                                                    "gst_included"
                                                  )
                                                }
                                              />
                                            </TableBodyRowValue2>
                                            <TableBodyRowValue2>
                                              <ContentSection1 color="#FE6B00">
                                                <PriceView>
                                                  {(item?.unit_cost ?? 0) *
                                                    (item?.unit_no ?? 0)}
                                                </PriceView>
                                              </ContentSection1>
                                            </TableBodyRowValue2>
                                            <TableBodyRowValue2>
                                              <ItemOtherForm>
                                                <InputPrice
                                                  disabled={isBlockEdit}
                                                  style={{ width: 80 }}
                                                  min={0}
                                                  value={
                                                    item?.final ??
                                                    (item?.unit_cost ?? 0) *
                                                      (item?.unit_no ?? 0)
                                                  }
                                                  onChange={(e: any) =>
                                                    onChangeOtherFees(
                                                      e,
                                                      "number",
                                                      key,
                                                      "fee_items",
                                                      indexItem,
                                                      "final"
                                                    )
                                                  }
                                                />
                                              </ItemOtherForm>
                                            </TableBodyRowValue2>
                                          </TableBodyRow>
                                          {value?.fee_items?.length > 2 &&
                                            indexItem % 2 === 1 && (
                                              <hr
                                                style={{
                                                  borderColor: "#ffffff",
                                                  marginLeft: "16px",
                                                }}
                                              />
                                            )}
                                        </>
                                      )
                                    )
                                  : null}
                              </TableBody>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        span={12}
                        style={{
                          paddingLeft: "32px",
                          borderLeft: "1px solid #DDDDDD",
                        }}
                      >
                        {renderCommentByKeyName(key)}
                        <SpaceView size={10} />
                        {isComment && (
                          <SimpleMentionEditor
                            mentions={mentionDoctor}
                            onSubmit={(content: string) =>
                              sendComment(key, content)
                            }
                          />
                        )}
                      </Col>
                    </Row>
                  </>
                );
              }
            }
          )}
        <Row>
          <TableFooter color="#55BED2" style={{ height: 92 }}>
            <TableFooterTitleCustom>
              Item(s) charged under Letter of Certification (LC):
            </TableFooterTitleCustom>
            <TableFooterValue></TableFooterValue>
            <TableFooterValue>
              <ContentSection1 color="#FE6B00">Suggested</ContentSection1>
              <SpaceView size={4} />
              <PriceView color="#FE6B00">
                {getTotalSuggestOtherFee(data)}
              </PriceView>
            </TableFooterValue>
            <TableFooterValue>
              <ContentSection1 color="#01A2FF">Final</ContentSection1>
              <SpaceView size={4} />
              <PriceView color="#01A2FF">
                {getTotalFinalOtherFee(data)}
              </PriceView>
            </TableFooterValue>
          </TableFooter>
        </Row>
      </SessionContainer>
      <SpaceView size={44} />
      <Row style={{ alignItems: "center", position: "relative" }}>
        <Col span={21}>
          <SessionDivider value={{ index: 4 }} />
        </Col>
        {/*<EditComponent />*/}
      </Row>
      <SessionContainer>
        <Row>
          <Col span={24}>
            <TitleSession>Hospital Charges</TitleSession>
          </Col>
        </Row>
        <Row>
          <TableFooter color="#55BED2" style={{ height: 92 }}>
            <TableFooterTitle>Total Hospital Charges:</TableFooterTitle>
            <TableFooterValue></TableFooterValue>
            <TableFooterValue>
              <ContentSection1 color="#FE6B00">Suggested</ContentSection1>
              <SpaceView size={4} />
              <PriceView color="#FE6B00">
                {data?.total_hospital_charges}
              </PriceView>
            </TableFooterValue>
            <TableFooterValue
              style={{ position: "relative" }}
              className="total-hospital"
            >
              <ContentSection1 color="#01A2FF">Final</ContentSection1>
              <SpaceView size={32} />
              <ItemOtherForm
                style={{ position: "absolute", top: 30, right: "-5%" }}
              >
                <InputPrice
                  disabled={isBlockEdit}
                  height={24}
                  value={
                    data?.final_hospital_charges ?? data?.total_hospital_charges
                  }
                  onChange={(e: any) =>
                    onChangeInputText(e, "final_hospital_charges", "number")
                  }
                  style={{
                    height: 24,
                    width: "80%",
                  }}
                  min={0}
                />
              </ItemOtherForm>
            </TableFooterValue>
            <TableBodyRowValue2>
              <Col>
                <ContentSection1>GST</ContentSection1>
                <Checkbox
                  disable={isBlockEdit}
                  onChange={(e: any) =>
                    onChangeInputText(e, "gst_hospital_charges", "checkbox")
                  }
                  checked={data?.gst_hospital_charges}
                />
              </Col>
            </TableBodyRowValue2>
          </TableFooter>
        </Row>
        <SpaceView size={44} />
        <hr style={{ borderColor: "#DDDDDD" }} />
        <SpaceView size={34} />
        <Row>
          <Col span={24}>
            {renderCommentByKeyName("total_hospital_charges")}
            <SpaceView size={10} />
            {isComment && (
              <SimpleMentionEditor
                mentions={mentionDoctor}
                onSubmit={(content: string) =>
                  sendComment("total_hospital_charges", content)
                }
              />
            )}
          </Col>
        </Row>
      </SessionContainer>
      {/*  */}
      <SpaceView size={44} />
      <Row
        className="break-before"
        style={{ alignItems: "center", position: "relative" }}
      >
        <Col span={21}>
          <SessionDivider value={{ index: 5 }} />
        </Col>
        {/*<EditComponent />*/}
      </Row>
      <SessionContainer>
        <Row>
          <Col span={24}>
            <TitleSession>Bill Size=1+2+3+4</TitleSession>
          </Col>
        </Row>
        <Row>
          <TableFooter color="#55BED2" style={{ height: 92 }}>
            <TableFooterTitle>Total Bill Size:</TableFooterTitle>
            <TableFooterValue></TableFooterValue>
            <TableFooterValue>
              <ContentSection1 color="#FE6B00">Suggested</ContentSection1>
              <SpaceView size={4} />
              <PriceView color="#FE6B00">{getTotalBillSize(data)}</PriceView>
            </TableFooterValue>
            <TableFooterValue>
              <ContentSection1 color="#01A2FF">Final</ContentSection1>
              <SpaceView size={4} />
              <PriceView color="#01A2FF">
                {getTotalFinalBillSize(data)}
              </PriceView>
            </TableFooterValue>
          </TableFooter>
        </Row>
      </SessionContainer>
      {/*  */}
      <SpaceView size={44} />
      <Row style={{ alignItems: "center", position: "relative" }}>
        <Col span={21}>
          <SessionDivider value={{ index: 6 }} />
        </Col>
        {/*<EditComponent />*/}
      </Row>
      <SessionContainer>
        <Row
          style={{
            padding: "24px 10px",
            borderRadius: "24px",
            backgroundColor: "#F3F7FC",
          }}
        >
          <Col span={24}>
            <Row>
              <Col span={11}>
                <Row justify="space-between">
                  <LabelSection1>Non-Preauthorised Items</LabelSection1>
                  <LabelSection1>Amount</LabelSection1>
                </Row>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Row>
                  <LabelSection1>Details</LabelSection1>
                </Row>
              </Col>
            </Row>
            <SpaceView size={16} />
            <Row
              style={{
                padding: "24px 10px",
                borderRadius: "24px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Col span={24}>
                <Row>
                  <Col span={7}>
                    <Row justify="space-between" className="items-center">
                      <ContentSection8Custom color="#101820">
                        Special comment to LA
                      </ContentSection8Custom>
                    </Row>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={16}>
                    <Row justify="space-between" className="items-center">
                      <TextArea
                        disabled={isBlockEdit}
                        placeholder="Describe"
                        className="disable-bg-black"
                        autoSize={{ minRows: 1 }}
                        value={data?.special_comment_to_la}
                        onChange={(e: any) =>
                          onChangeInputText(e, "special_comment_to_la", "text")
                        }
                      />
                    </Row>
                  </Col>
                </Row>
                <SpaceView size={16} />

                <Row>
                  <Col span={7}>
                    <Row justify="space-between" className="items-center">
                      <ContentSection8Custom color="#101820">
                        Special comment to Clinic
                      </ContentSection8Custom>
                    </Row>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={16}>
                    <Row justify="space-between" className="items-center">
                      <TextArea
                        disabled={isBlockEdit}
                        placeholder="Describe"
                        className="disable-bg-black"
                        autoSize={{ minRows: 1 }}
                        value={data?.special_comment_to_clinic}
                        onChange={(e: any) =>
                          onChangeInputText(
                            e,
                            "special_comment_to_clinic",
                            "text"
                          )
                        }
                      />
                    </Row>
                  </Col>
                </Row>
                <SpaceView size={16} />

                <Row>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <ContentSection8Custom color="#101820">
                        General exclusion
                      </ContentSection8Custom>
                      <InputPrice
                        disabled={isBlockEdit}
                        className="disable-bg-black"
                        height={50}
                        style={{
                          height: 50,
                          width: "100px",
                        }}
                        min={0}
                        value={data?.general_exclusion}
                        onChange={(e: any) =>
                          onChangeInputText(e, "general_exclusion", "number")
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <TextArea
                        disabled={isBlockEdit}
                        placeholder="Describe"
                        className="tung-hehe disable-bg-black"
                        autoSize={{ minRows: 1 }}
                        value={data?.desctiption_general_exclusion}
                        onChange={(e: any) =>
                          onChangeInputText(
                            e,
                            "desctiption_general_exclusion",
                            "text"
                          )
                        }
                      />
                    </Row>
                  </Col>
                </Row>
                <SpaceView size={16} />
                <Row>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <ContentSection8Custom color="#101820">
                        Policy exclusion
                      </ContentSection8Custom>
                      <InputPrice
                        disabled={isBlockEdit}
                        className="disable-bg-black"
                        height={50}
                        style={{
                          height: 50,
                          width: "100px",
                        }}
                        min={0}
                        value={data?.policy_exclusion}
                        onChange={(e: any) =>
                          onChangeInputText(e, "policy_exclusion", "number")
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <TextArea
                        className="tung-hehe disable-bg-black"
                        disabled={isBlockEdit}
                        value={data?.desctiption_policy_exclusion}
                        onChange={(e: any) =>
                          onChangeInputText(
                            e,
                            "desctiption_policy_exclusion",
                            "text"
                          )
                        }
                        placeholder="Describe"
                        autoSize={{ minRows: 1 }}
                      />
                    </Row>
                  </Col>
                </Row>
                <SpaceView size={16} />
                <Row>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <ContentSection8Custom color="#101820">
                        Pro-ration
                      </ContentSection8Custom>
                      <InputPrice
                        className="disable-bg-black"
                        disabled={isBlockEdit}
                        height={50}
                        style={{
                          height: 50,
                          width: "100px",
                        }}
                        min={0}
                        value={data?.proration}
                        onChange={(e: any) =>
                          onChangeInputText(e, "proration", "number")
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <TextArea
                        disabled={isBlockEdit}
                        className="tung-hehe disable-bg-black"
                        placeholder="Describe"
                        autoSize={{ minRows: 1 }}
                        value={data?.description_proration}
                        onChange={(e: any) =>
                          onChangeInputText(e, "description_proration", "text")
                        }
                      />
                    </Row>
                  </Col>
                </Row>
                <SpaceView size={16} />
                <Row>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <ContentSection8Custom color="#101820">
                        Exceed policy benefit capped limit (for Non-As-Charged
                        policy)
                      </ContentSection8Custom>
                      <InputPrice
                        className="disable-bg-black"
                        disabled={isBlockEdit}
                        height={50}
                        style={{
                          height: 50,
                          width: "100px",
                        }}
                        min={0}
                        value={data?.non_as_charged}
                        onChange={(e: any) =>
                          onChangeInputText(e, "non_as_charged", "number")
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <TextArea
                        disabled={isBlockEdit}
                        placeholder="Describe"
                        className="tung-hehe disable-bg-black"
                        autoSize={{ minRows: 1 }}
                        value={data?.desctiption_non_as_charged}
                        onChange={(e: any) =>
                          onChangeInputText(
                            e,
                            "desctiption_non_as_charged",
                            "text"
                          )
                        }
                      />
                    </Row>
                  </Col>
                </Row>
                <SpaceView size={16} />
                <Row>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <ContentSection8Custom color="#101820">
                        Deductible (if no rider)
                      </ContentSection8Custom>
                      <InputPrice
                        className="disable-bg-black"
                        disabled={isBlockEdit}
                        height={50}
                        style={{
                          height: 50,
                          width: "100px",
                        }}
                        min={0}
                        value={data?.or_deductible}
                        onChange={(e: any) =>
                          onChangeInputText(e, "or_deductible", "number")
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <TextArea
                        className="tung-hehe disable-bg-black"
                        disabled={isBlockEdit}
                        placeholder="Describe"
                        autoSize={{ minRows: 1 }}
                        value={data?.description_or_deductible}
                        onChange={(e: any) =>
                          onChangeInputText(
                            e,
                            "description_or_deductible",
                            "text"
                          )
                        }
                      />
                    </Row>
                  </Col>
                </Row>
                <SpaceView size={16} />
                <Row>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <ContentSection8Custom color="#101820">
                        10% co-insurance (if no rider and deductibles fully met)
                      </ContentSection8Custom>
                      <InputPrice
                        className="disable-bg-black"
                        disabled={isBlockEdit}
                        height={50}
                        style={{
                          height: 50,
                          width: "100px",
                        }}
                        min={0}
                        value={data?.co_insurance_alway}
                        onChange={(e: any) =>
                          onChangeInputText(e, "co_insurance_alway", "number")
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <TextArea
                        className="tung-hehe disable-bg-black"
                        disabled={isBlockEdit}
                        placeholder="Describe"
                        autoSize={{ minRows: 1 }}
                        value={data?.description_co_insurance_alway}
                        onChange={(e: any) =>
                          onChangeInputText(
                            e,
                            "description_co_insurance_alway",
                            "text"
                          )
                        }
                      />
                    </Row>
                  </Col>
                </Row>
                <SpaceView size={16} />
                <Row>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <ContentSection8Custom color="#101820">
                        5% co-payment (capped at $3000)
                      </ContentSection8Custom>
                      <InputPrice
                        className="disable-bg-black"
                        disabled={isBlockEdit}
                        height={50}
                        style={{
                          height: 50,
                          width: "100px",
                        }}
                        min={0}
                        value={data?.co_payment}
                        onChange={(e: any) =>
                          onChangeInputText(e, "co_payment", "number")
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <TextArea
                        className="tung-hehe disable-bg-black"
                        disabled={isBlockEdit}
                        placeholder="Describe"
                        autoSize={{ minRows: 1 }}
                        value={data?.description_co_payment}
                        onChange={(e: any) =>
                          onChangeInputText(e, "description_co_payment", "text")
                        }
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <SpaceView size={16} />
            <Row
              style={{
                padding: "24px 10px",
                borderRadius: "24px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Col span={24}>
                <Row>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <LabelSection1>Total non-preauth amount</LabelSection1>
                      <InputPrice
                        className="disable-bg-black"
                        disabled={isBlockEdit}
                        height={50}
                        style={{
                          height: 50,
                          width: "100px",
                        }}
                        min={0}
                        value={data?.total_non_preauth_amount}
                        onChange={(e: any) =>
                          onChangeInputText(
                            e,
                            "total_non_preauth_amount",
                            "number"
                          )
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <TextArea
                        className="tung-hehe disable-bg-black"
                        disabled={isBlockEdit}
                        placeholder="Describe"
                        autoSize={{ minRows: 1 }}
                        value={data?.description_total_non_preauth_amount}
                        onChange={(e: any) =>
                          onChangeInputText(
                            e,
                            "description_total_non_preauth_amount",
                            "text"
                          )
                        }
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <SpaceView size={16} />
            <Row
              style={{
                padding: "24px 10px",
                borderRadius: "24px",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Col span={24}>
                <Row>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <LabelSection1>Total pre-auth amount</LabelSection1>
                      <InputPrice
                        className="disable-bg-black"
                        disabled={isBlockEdit}
                        height={50}
                        style={{
                          height: 50,
                          width: "100px",
                        }}
                        min={0}
                        value={data?.total_preauth_amount}
                        onChange={(e: any) =>
                          onChangeInputText(e, "total_preauth_amount", "number")
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={11}>
                    <Row justify="space-between" className="items-center">
                      <TextArea
                        className="tung-hehe disable-bg-black"
                        disabled={isBlockEdit}
                        placeholder="Describe"
                        autoSize={{ minRows: 1 }}
                        value={data?.description_total_preauth_amount}
                        onChange={(e: any) =>
                          onChangeInputText(
                            e,
                            "description_total_preauth_amount",
                            "text"
                          )
                        }
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </SessionContainer>
      {/*  */}
      <SpaceView size={44} />
      <Row
        className="break-before"
        style={{ alignItems: "center", position: "relative" }}
      >
        <Col span={21}>
          <SessionDivider value={{ index: 7 }} />
        </Col>
        {/*<EditComponent />*/}
      </Row>
      <SessionContainer>
        <SpaceView size={28} />
        <Row>
          <Col span={24}>
            <LabelSection1>Assessment</LabelSection1>
            <SpaceView size={16} />
            <Row>
              <TextArea
                disabled={isBlockEdit}
                status={errors.assessment ? "error" : ""}
                value={data?.assessment}
                onChange={(e: any) =>
                  onChangeInputText(e, "assessment", "text")
                }
                placeholder="Input text"
                autoSize={{ minRows: 5 }}
              />
            </Row>
            {errors.assessment && (
              <ContentSection1 color="red">{errors.message}</ContentSection1>
            )}
          </Col>
        </Row>
        <SpaceView size={28} />
        <Row>
          <Col span={24}>
            <LabelSection1>Case tags</LabelSection1>
            <SpaceView size={16} />
            {caseTagContent(data)}
          </Col>
        </Row>
        <SpaceView size={28} />
        <Row>
          <Col span={24}>
            <LabelSection1>Recommendation</LabelSection1>
            <SpaceView size={16} />
            <Row>
              <Select
                disabled={isBlockEdit}
                placeholder="Select Recommendation"
                value={data?.recommendation}
                onChange={onChangeRecommendation}
                style={{ width: "35%" }}
              >
                <Option value="yes">Yes</Option>
                <Option value="no_within_deductible">
                  No - Within Deductible
                </Option>
                <Option value="no_ge_guidelines">No - GE Guidelines</Option>
                <Option value="no_insufficient_info">
                  No - Insufficient Info/Short Policy Duration",
                </Option>
                <Option value="no_falls_under_exclusion">
                  No - Falls under exclusion
                </Option>
                <Option value="no_previous_claim_pending">
                  No - Previous claim pending
                </Option>
                <Option value="no_corporate_log_covered_in_full">
                  No - Corporate LOG covered in full
                </Option>
              </Select>
            </Row>
          </Col>
        </Row>
        <SpaceView size={28} />
        <Row>
          <Col span={24}>
            <LabelSection1>GE's advice/ concurrence (*if any)</LabelSection1>
            <SpaceView size={16} />
            <Row>
              <TextArea
                disabled={isBlockEdit}
                status={errors.ge_advice_concurrence ? "error" : ""}
                value={data?.ge_advice_concurrence}
                onChange={(e: any) =>
                  onChangeInputText(e, "ge_advice_concurrence", "text")
                }
                placeholder="Input text"
                autoSize={{ minRows: 5 }}
              />
            </Row>
            {errors.ge_advice_concurrence && (
              <ContentSection1 color="red">{errors.message}</ContentSection1>
            )}
          </Col>
        </Row>
        <SpaceView size={28} />
        <Row>
          <Col span={24}>
            <LabelSection1>Appeal (*if any)</LabelSection1>
            <SpaceView size={16} />
            <Row>
              <TextArea
                disabled={isBlockEdit}
                value={data?.appeal}
                onChange={(e: any) => onChangeInputText(e, "appeal", "text")}
                placeholder="Input text"
                autoSize={{ minRows: 5 }}
              />
            </Row>
          </Col>
        </Row>
      </SessionContainer>
    </div>
  );
};

const StyleTagActive = {
  border: "1px solid #55BED2",
  backgroundColor: "#edfbfc",
};

const CellValueSuggest = styled.div`
  height: 24px;
  width: calc(100% / 4);
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  border: 1px solid #dddddd;
  border-radius: 16px;
`;

export const InputNumber = styled(antd.InputNumber)`
  &.ant-input-number {
    height: 24px;
    box-sizing: border-box;
    border-radius: 16px;
    overflow: hidden;
    border: 1px solid #dddddd;
  }

  & .ant-input-number-input-wrap {
    background-color: transparent !important;
    height: ${(props) => (props?.height ? `${props.height}px` : "24px")};
  }

  & .ant-input-number-input {
    height: ${(props) => (props?.height ? `${props.height}px` : "24px")};
    padding-left: 24px;
    text-align: right;
    padding-right: 30px;
    font-weight: ${(props: any) =>
      props?.fontWeight ? `${props.fontWeight}` : "400"};
    font-size: 15px;
    line-height: 24px;
    color: ${(props) => props?.theme?.color || "#101820"} !important;

    ::placeholder {
      color: #727c8a;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #727c8a;
    }

    ::-ms-input-placeholder {
      color: #727c8a;
    }
  }

  &.ant-input-number-focused {
    border: 1px solid #55bed2 !important;
    box-shadow: 0 0 0 2px rgba(85, 190, 210, 0.2) !important;
  }
`;

const ContainerPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  height: 24px;
  position: relative;
`;

const PriceIcon = (props: any) => (
  <div {...props}>
    <span
      style={{
        lineHeight: "24px",
        height: 24,
        fontWeight: 300,
        color: "#6f6f6f",
        ...(props.styleIconSvg || {}),
        fontSize: 16,
      }}
    >
      $
    </span>
  </div>
);

const CloseIcon = (props: any) => {
  const currentProps = { ...props, float: undefined };
  return (
    <div {...currentProps}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
          fill="white"
        />
        <path
          d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM16.3 16.3C15.91 16.69 15.28 16.69 14.89 16.3L12 13.41L9.11 16.3C8.72 16.69 8.09 16.69 7.7 16.3C7.31 15.91 7.31 15.28 7.7 14.89L10.59 12L7.7 9.11C7.31 8.72 7.31 8.09 7.7 7.7C8.09 7.31 8.72 7.31 9.11 7.7L12 10.59L14.89 7.7C15.28 7.31 15.91 7.31 16.3 7.7C16.69 8.09 16.69 8.72 16.3 9.11L13.41 12L16.3 14.89C16.68 15.27 16.68 15.91 16.3 16.3Z"
          fill="#828282"
        />
      </svg>
    </div>
  );
};

export const ItemClose = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  ${(props: { float?: boolean }) =>
    props?.float
      ? `
    position: absolute;
    right: -8px;
    top: -4px;
    margin-left: 16px;
`
      : null}
`;

export const InputPrice = (props: any) => {
  const { height, fontWeight, value } = props;
  const newValue = parseFloat(parseFloat(value ?? 0).toFixed(4));
  return (
    <ContainerPrice style={{ ...(props.style ? props.style : {}) }}>
      {!props.isNotIcon && (
        <PriceIcon
          className="price-icon"
          styleIconSvg={props.styleIconSvg || {}}
          style={{
            height: 24,
            position: "absolute",
            zIndex: 9,
            left: 10,
            ...(props.styleIcon ? props.styleIcon : {}),
            fontSize: "16px",
          }}
        />
      )}
      <InputNumber
        disabled={props.disabled ?? false}
        height={height}
        fontWeight={fontWeight}
        theme={{ color: props?.styleIconSvg?.color }}
        style={props.style ? props.style : {}}
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
        {...props}
        value={newValue}
      />
    </ContainerPrice>
  );
};

const ContentSection8Custom = styled(ContentSection8)`
  max-width: calc(100% - 170px);
`;

const TableFooterTitleCustom = styled(TableFooterTitle)`
  @media (max-width: 1440px) {
    min-width: calc(30%);
  }
`;

export default forwardRef(SessionB);
